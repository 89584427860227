.become-provider-section .custom-container {
  max-width: 1920px;
}

.become-provider-image {
  margin-top: -300px;
}

.become-provider-image {
  margin-top: -300px;
}

.become-provider-image {
  position: relative;
}

.become-provider-image a {
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translateX(-50%);
}

section.become-provider-section {
  margin-top: 150px;
}

@media screen and (max-width: 1680px) {
  .become-provider-image {
    margin-top: -190px;
  }
}

@media screen and (max-width: 1199px) {
  .become-provider-image {
    margin-top: -120px;
  }
}

@media screen and (max-width: 767px) {
  .become-provider-image {
    margin-top: 0;
  }

  .become-provider-image a {
    position: static;
    transform: unset;
  }
}
