.select-method-container {
	display: flex;
	flex-direction: column;
	gap: 48px;
}

.custom-radio-button {
	display: flex;
	gap: 16px;
	cursor: pointer;
}

.custom-radio-button h4 {
	color: #fc4ba3;
	font-size: 1rem !important;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
	margin-bottom: 8px !important;
}

.custom-radio-button p {
	color: #fff;
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
	margin: 0;
}

.select-recipient-container {
	display: flex;
	gap: 32px;
	align-items: center;
	margin-top: 24px;
}

.select-recipient-container .ens-address {
	margin-top: 4px;
	font-size: 0.675rem;
	text-decoration: underline;
	margin-left: 16px;
	color: #fc4ba3;
}

.select-recipient-container.flex-col {
	flex-direction: column;
	align-items: flex-start;
	gap: 0;
}

.select-recipient-container img {
	width: 40px;
	height: 40px;
	border-radius: 20px;
}

.input-with-image {
	position: relative;
	display: inline-block;
}
ptokenbouncs .input-image {
	position: absolute;
	left: 12px;
	top: 50%;
	transform: translateY(-50%);
	width: 30px;
	height: auto;
}

.input-with-image input[type="text"] {
	padding-left: 64px;
}

.cutom-contract-input::placeholder {
	position: absolute;
	left: 16px;
}

.select-recipient-input {
	display: flex;
	width: 401px;
	padding: 12px 16px;
	align-items: center;
	gap: 16px;
	border-radius: 12px;
	border: 2px solid #fc4ba3;
	box-shadow: 0px 0px 3px 1px rgba(108, 108, 108, 0.05) inset,
		0px 2px 6px 0px rgba(108, 108, 108, 0.05);
	background: none;
	color: #fc4ba3;
}

.select-recipient-input::placeholder {
	color: #fc4ba3;
}

.select-recipient-input.token-id {
	display: flex;
	width: 73px;
	padding: 12px 16px;
	align-items: center;
	gap: 16px;
	border-radius: 12px;
	border: 2px solid #fc4ba3;
	box-shadow: 0px 0px 3px 1px rgba(108, 108, 108, 0.05) inset,
		0px 2px 6px 0px rgba(108, 108, 108, 0.05);
	text-align: right;
}

.select-recipient-input.token-id::placeholder {
	text-align: right;
}

.search-result {
	margin-top: 12px;
	display: flex;
	width: 401px;
	padding: 4px;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;
	border-radius: 12px;
	border: 2px solid #fc4ba3;
	background: #fff;

	box-shadow: 0px 0px 3px 1px rgba(108, 108, 108, 0.05) inset,
		0px 2px 6px 0px rgba(108, 108, 108, 0.05);
	min-height: 40px;
	position: absolute;
	height: 300px; /* Set a fixed height for the div */
	overflow: auto; /* Enable vertical scrolling */
	border: 1px solid #ccc; /* Add a border for visibility */

	z-index: 999;
}

.search-result ul {
	padding: 0;
	margin: 0;
	width: 100%;
}

.search-result li {
	list-style: none;
	border-radius: 8px;
	display: flex;
	padding: 8px 16px;
	align-items: center;
	gap: 20px;
	align-self: stretch;
	width: 100%;
	color: #fc4ba3;
}
.search-result li:hover {
	background: rgba(63, 236, 198, 0.33);
	cursor: pointer;
}

.search-result img {
	width: 32px;
	height: 32px;
	border-radius: 16px;
}

.search-result::-webkit-scrollbar {
	width: 10px; /* Width of the scrollbar */
	background: none;
}

.search-result::-webkit-scrollbar-thumb {
	background-color: #fff; /* Color of the scrollbar thumb */
	border-radius: 5px; /* Border radius of the thumb */
	margin-right: 8px;
}

.search-result {
	scrollbar-width: thin;
}

.search-result::-webkit-scrollbar-track {
	background-color: #fc4ba3;
	margin-top: 20px;
	margin-bottom: 150px;
	border-radius: 4px;
}
