.punk {
  image-rendering: auto;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
  width: 332px;
}

.name-with-tag {
  display: flex;
  align-items: start;
  justify-content: center;
}

.list-tag {
  position: relative;
  top: 10px;
}
