html,
body {
	height: 100%;
}

:root {
	--tooltip-text-color: white;
	--tooltip-background-color: black;
	--tooltip-margin: 30px;
	--tooltip-arrow-size: 6px;
	--onboard-connect-content-width: 800px;
	--onboard-font-family-normal: Futura LT;
}
.notice-container.svelte-kpc6js {
	display: none !important;
}

@media only screen and (max-width: 991px) {
	:root {
		--onboard-connect-content-width: 600px;
	}
}

@media only screen and (max-width: 540px) {
	:root {
		--onboard-connect-content-width: 360px;
	}
}

body {
	background-color: #030a34;
	font-family: "Futura LT";
}

img {
	max-width: 100%;
}

.banner-shape {
	top: 50%;
	transform: translateY(-50%);
}
input,
textarea {
	-webkit-user-select: text;
}

.text-green {
	color: #3fecc6 !important;
}

.text-pink {
	color: #fc4ba3 !important;
}

.text-blue {
	color: #0955ce !important;
}

.text-golden {
	color: #ad8f45 !important;
}

.bordered-button {
	font-size: 16px;
	color: #3fecc6;
	font-weight: 300;
	text-decoration: none;
	border: 5px solid #18007f;
	text-transform: uppercase;
	display: inline-block;
	padding: 7px 28px;
	border-radius: 50px;
	transition: 0.3s;
	min-width: 170px;
}
.bordered-button:hover {
	background-color: #025e60;
	color: #3fecc6;
	box-shadow: 0px 0px 20px #3000ff;
}

.bordered-button.pink-border {
	border-color: #fc4ba3;
	box-shadow: 0px 0px 20px #3000ff;
}

.bordered-button-2 {
	font-size: 16px;
	color: #3fecc6;
	font-weight: 300;
	text-decoration: none;
	border: 5px solid #18007f;
	text-transform: uppercase;
	display: inline-block;
	padding: 7px 28px;
	border-radius: 50px;
	transition: 0.3s;
	min-width: 200px;
}
.bordered-button-2:hover {
	background-color: #025e60;
	color: #3fecc6;
	box-shadow: 0px 0px 20px #3000ff;
}

.bordered-button-2.pink-border {
	border-color: #fc4ba3;
	box-shadow: 0px 0px 20px #3000ff;
}

.account-bar {
	text-align: center;
	display: flex;
	align-items: center;
	gap: 16px;
}

.address-image-display {
	display: flex;
	align-items: center;
	gap: 10px;
}

.account-button {
	min-width: 90px;
}

.nftr-text-box h2 {
	color: #3fecc6;
	font-size: 60px;
	font-weight: 300;
}

.nftr-text-box h2 strong {
	font-weight: bold;
}

.nftr-text-box p {
	font-size: 24px;
	color: #fff;
	margin-top: 5px;
	line-height: 1.2;
}

.nftr-text-box h2 {
	position: relative;
}

.pink-bar {
	margin-left: -20px;
	margin-top: -40px;
}

.pink-bar-2 {
	margin-left: -20px;
	margin-top: -20px;
	margin-bottom: -40px;
}

@media screen and (max-width: 1199px) {
	.nftr-text-box h2 {
		font-size: 35px;
	}
}

@media screen and (max-width: 991px) {
	button.navbar-toggler {
		background-color: #3fecc6;
	}
	header {
		background-color: #0e1544;
	}

	.navbar-collapse {
		height: 400px;
	}

	a.navbar-brand img {
		max-width: 45px;
	}
	header .nav-link {
		text-align: center;
		margin-bottom: 15px;
	}

	header .text-end {
		text-align: center !important;
		padding-bottom: 15px;
	}
}

header .nav-link {
	transition: 0.3s;
}

.service-box {
	transition: 0.3s;
}

.service-box:hover {
	transform: translateY(-5px);
}

.project-box,
.nft-name-box {
	transition: 0.3s;
}

.project-box:hover,
.nft-name-box:hover {
	transform: translateY(-5px);
}

.cta-text a {
	transition: 0.3s;
}

.cta-text a:hover {
	background-color: #ff7bc3;
	color: #fff;
}

.footer-menu ul li a {
	transition: 0.3s;
}

.footer-menu ul li a:hover {
	color: #fff;
}

.bordered-button.pink-border:hover {
	background-color: #fc4ba3;
	color: #fff;
}

a.primary-btn:hover {
	background-color: #000;
}

a.primary-btn {
	transition: 0.3s;
}

.become-provider-section .nftr-text-box img {
	margin-top: -40px;
}

@media screen and (max-width: 585px) {
	.nftr-text-box h2 {
		font-size: 28px;
	}

	.nftr-text-box p {
		font-size: 18px;
	}
}

.account-class {
	text-align: right;
}

.header-two .navbar {
	max-height: 320px;
	margin-top: 40px;
	margin-bottom: 20px;
}

.header-two .navbar-nav a {
	position: relative;
	z-index: 1;
}

.header-two .navbar-nav a {
	padding: 0 !important;
	margin: 0 20px;
}

.header-two .navbar-nav a:after {
	position: absolute;
	bottom: -5px;
	left: 0;
	width: 100%;
	height: 3px;
	background-color: #fc4ba3;
	content: "";
	border-radius: 50px;
	opacity: 0;
	visibility: hidden;
	transition: 0.3s;
}

.header-two .navbar-nav a:hover:after,
.header-two .navbar-nav .specialnavlink a.active:after {
	opacity: 1;
	visibility: visible;
}

.header-one .navbar {
	max-height: 360px;
	margin-top: 40px;
	margin-bottom: 60px;
}

.header-one .navbar-nav a {
	position: relative;
	z-index: 1;
}

.header-one .navbar-nav a {
	padding: 0 !important;
	margin: 0 20px;
}

.header-one .navbar-nav a:after {
	position: absolute;
	bottom: -5px;
	left: 0;
	width: 100%;
	height: 3px;
	background-color: #fc4ba3;
	content: "";
	border-radius: 50px;
	opacity: 0;
	visibility: hidden;
	transition: 0.3s;
}

.header-one .navbar-nav a:hover:after,
.header-one .navbar-nav .specialnavlink a.active:after {
	opacity: 1;
	visibility: visible;
}

section.lets-browse-search .shapes {
	position: relative;
	z-index: -1000;
}

section.lets-browse-search .shapes .left-shape {
	position: absolute;
	left: 0;
	top: 0;
}

section.lets-browse-search .shapes .right-shape {
	position: absolute;
	right: 0;
}

.wallet {
	text-align: right;
}

.wallet h4 {
	font-size: 15px;
	font-weight: bold;
	color: #0269ec;
	/* margin-bottom: 10px; */
}

.wallet p {
	font-size: 15px;
	color: #fff;
	margin-bottom: 0;
}

.wallet p span {
	font-weight: bold;
}

.lets-browse-search-text h1 {
	font-size: 92px;
	font-weight: bold;
	text-transform: uppercase;
	margin-bottom: 45px;
}

.lets-browse-search-text p {
	font-size: 25px;
	margin-bottom: 50px;
}

.lets-browse-search-text input[type="search"] {
	background-color: transparent;
	border: 10px solid #fc4ba3;
	box-shadow: 0 0 30px #fc4ba3;
	border-radius: 180px;
	padding: 15px 0;
	font-size: 90px;
	padding-left: 50px;
	padding-right: 50px;
	color: #fff;
}

.lets-browse-search-text input[type="search"]::placeholder {
	color: #0f3751;
}

.lets-browse-search-text {
	position: relative;
	z-index: 2;
}

section.robot-lists {
	margin-top: 100px;
}

.robot-slide-item span {
	font-size: 24px;
}

.react-select {
	background-color: transparent;
	border: 5px solid #fc4ba3;
	box-shadow: 0 0 30px #fc4ba3;
	border-radius: 180px;
	padding: 15px 0;
	font-size: 90px;
	padding-left: 50px;
	padding-right: 50px;
	color: #fff;
}

.robot-slide-item {
	text-align: center;
	color: #3fecc6;
	font-weight: 300;
	text-transform: uppercase;
	border: 5px solid #100080;
	padding: 10px 15px;
	border-radius: 50px;
	transition: 0.3s;
}

.robot-slide-item:hover {
	border-color: #fc4ba3;
	cursor: pointer;
	box-shadow: 0 0 15px #100080;
}

.robot-slide-item.active {
	border-color: #fc4ba3;
	box-shadow: 0 0 15px #100080;
}

.robot-lists-slider .slick-slide > div {
	margin: 15px;
}

.robot-lists-slider .slick-prev {
	position: absolute;
	left: -60px;
	top: 50%;
	transform: translateY(-50%);
}

.robot-lists-slider .slick-next {
	position: absolute;
	right: -60px;
	top: 50%;
	transform: translateY(-50%);
}

section.robot-lists {
	position: relative;
	padding-bottom: 30px;
	margin-bottom: 60px;
}

section.robot-lists:after {
	position: absolute;
	bottom: 0;
	left: 50%;
	background-color: #100080;
	content: "";
	width: 65%;
	height: 5px;
	transform: translateX(-50%);
}

.robot-image-border {
	border: 6px solid #100080;
	margin-bottom: 15px;
	padding: 4px; /*
  margin-bottom: 30px; */
}

.robot-image-border-container {
	height: 100%;
	display: "flex";
	justify-content: center;
	flex-direction: column;
}

.quick-select {
	font-size: 20px;
	color: #0269ec;
	margin-bottom: 35px;
}

.robot-filter {
	font-size: 30px;
	font-weight: bold;
	color: #0269ec;
	margin-bottom: 35px;
}

.robot-filter span {
	transition: 0.3s;
}

.robot-filter span:hover {
	cursor: pointer;
}

.robot-filter span.active {
	color: #3fecc6;
}

.pagination-robots button,
.pagination-robots span {
	font-size: 60px;
	text-decoration: none;
	display: inline-block;
	width: 100px;
	border: 4px solid #100080;
	height: 100px;
	line-height: 93px;
	border-radius: 100px;
	color: #3fecc6;
	font-weight: bold;
	margin: 0 15px;
}

.pagination-robots span {
	color: #100080;
	/* border-color: #ff7bc3; */
	color: #3fecc6;
}

.pagination-robots span.three-dots {
	color: #3fecc6;
	border-color: #ff7bc3;
}

button.pagination-prev,
button.pagination-next {
	border: none;
	padding: 0;
	width: auto;
}

a.pagination-prev img,
a.pagination-next img {
	max-width: 50px;
}

.pagination-robots {
	margin-top: 100px;
}

.robot-card {
	height: 100%;
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
	justify-content: flex-end;
}

.robot-text h4 {
	font-size: 30px;
	font-weight: lighter;
	margin-bottom: 0;
}

.robot-text p {
	font-weight: bold;
	font-size: 30px;
	color: #3fecc6;
}
.robot-text .bordered-button {
	border: 2px solid;
}

.robot-text .bordered-button.pink-border {
	border-color: #fc4ba3;
	box-shadow: none;
}

section.invite-section {
	padding-top: 55px;
}

.invite-content h1 {
	color: #3fecc6;
	font-size: 94px;
	font-weight: bold;
}

.invite-content p {
	font-size: 34px;
	text-transform: uppercase;
	color: #0269ec;
}

.invite-content input[type="text"] {
	border: none;
	background-color: transparent;
	text-align: center;
	font-size: 94px;
	color: #fff;
}

.invite-content input[type="password"]::placeholder {
	color: #0f3751;
	font-size: 94px;
}

.invite-content input {
	border-bottom: 5px solid #fc4ba3 !important;
	border-radius: 0;
	margin-bottom: 50px;
}

.invite-content button {
	background-color: transparent;
	border: 6px solid #3fecc6;
	padding: 15px 400px;
	font-size: 94px;
	color: #fc4ba3;
	border-radius: 100px;
	text-transform: uppercase;
}
.invite-shapes .invite-shape-left {
	left: 0;
	top: 0;
}

.invite-shapes .invite-shape-right {
	right: 0;
	top: 0;
}

.invite-content {
	z-index: 2;
	position: relative;
}

section.invite-section {
	margin-bottom: 200px;
}

section.golden-ticket-section {
	padding-top: 60px;
}

.golden-ticket-shapes .gt-shape-left {
	right: 0;
	top: 0;
}

.golden-ticket-shapes .gt-shape-right {
	left: 0;
	top: 0;
}

.golden-ticket-content {
	z-index: 2;
	position: relative;
}

.golden-ticket-content p {
	font-size: 36px;
	color: #80809e;
	line-height: 1;
	max-width: 90%;
	margin: 30px auto;
}

.golden-ticket-content a.bordered-button {
	background-color: #032161;
	color: #fc4ba3;
	border: 6px solid #3fecc6;
	position: relative;
	font-size: 55px;
	font-weight: 500;
	padding: 10px 124px;
	border-radius: 100px;
}
.golden-ticket-content button.bordered-button {
	background-color: #032161;
	color: #fc4ba3;
	border: 6px solid #3fecc6;
	position: relative;
	font-size: 55px;
	font-weight: 500;
	padding: 10px 124px;
	border-radius: 100px;
	background-color: #022061;
}

.golden-ticket-content a.bordered-button:before {
	position: absolute;
	top: -10px;
	left: -13px;
	width: 104%;
	z-index: -1;
	content: "";
	border: 20px solid #022262;
	height: 129%;
	border-radius: 80px;
}
.golden-ticket-content button.bordered-button:before {
	position: absolute;
	top: -10px;
	left: -13px;
	width: 104%;
	z-index: -1;
	content: "";
	border: 20px solid #022262;
	height: 129%;
	border-radius: 80px;
	background-color: #022061;
}

.golden-ticket-ratio-content {
	position: relative;
}

.golden-ticket-ratio-content h5 {
	position: absolute;
	bottom: 45%;
	color: #fff;
	font-size: 30px;
}

section.golden-ticket-ratio {
	margin-top: 20px;
}

.golden-ticket-ratio-content p {
	color: #80809e;
	font-size: 38px;
	line-height: 1.2;
	margin-top: 30px;
}

.golden-ticket-ratio-content {
	margin-bottom: 100px;
}

.golden-ticket-box h4 {
	color: #fc4ba3;
	font-size: 50px;
	border-bottom: 4px solid #0269ec;
	padding-bottom: 5px;
}

.golden-ticket-box p {
	color: #767684;
	font-size: 32px;
	line-height: 1.2;
	margin-bottom: 0;
	margin-top: 35px;
}

button.bordered-button.blue-ticket-btn {
	background-image: linear-gradient(90deg, #0296bf, #02e9d8);
	color: #000036;
	font-size: 55px;
	width: 100%;
	border: 6px solid #fc4ba3;
	padding: 0;
	font-weight: 500;
	margin-top: 20px;
	box-shadow: 0 0 20px #3000ff;
}
button.bordered-button.blue-ticket-btn {
	background-image: linear-gradient(90deg, #0296bf, #02e9d8);
	color: #000036;
	font-size: 55px;
	width: 100%;
	border: 6px solid #fc4ba3;
	padding: 0;
	font-weight: 500;
	margin-top: 20px;
	box-shadow: 0 0 20px #3000ff;
}

button.bordered-button.yello-ticket-btn {
	background-image: linear-gradient(90deg, #eceb02, #ec5302);
	color: #000036;
	font-size: 55px;
	width: 100%;
	border: 6px solid #fc4ba3;
	padding: 0;
	font-weight: 500;
	margin-top: 20px;
	box-shadow: 0 0 20px #3000ff;
}
b.bordered-button.yello-ticket-btn {
	background-image: linear-gradient(90deg, #eceb02, #ec5302);
	color: #000036;
	font-size: 55px;
	width: 100%;
	border: 6px solid #fc4ba3;
	padding: 0;
	font-weight: 500;
	margin-top: 20px;
	box-shadow: 0 0 20px #3000ff;
}

section.robot-details {
	padding-top: 460px;
	margin-bottom: 200px;
}

/* .robot-details-collection-name {
  margin-bottom: 15px;
}

.robot-details-collection-name a {
  border-radius: 3px;
  border: 1px solid;
  padding: 2px 10px 2px 10px;
}
.robot-details-token-id {
  margin-top: 15px;
}
.robot-details-token-id a {
  border-radius: 3px;
  border: 1px solid;
  padding: 2px 10px 2px 10px;
} */
.robot-details-content .navigation {
	font-size: 46px;
	color: #0269ec;
	text-decoration: none;
	text-transform: uppercase;
	position: relative;
}

.robot-details-content .navigation i {
	color: #100080;
}

/* .robot-details-content .navigation:after {
	position: absolute;
	right: -35px;
	content: '';
	background-color: #fc4ba3;
	width: 10px;
	height: 10px;
	top: 50%;
	transform: translateY(-49%);
	border-radius: 50px;
} */

p.robot-id,
.robot-name {
	font-size: 52px;
	color: #0269ec;
	font-weight: bold;
	margin-bottom: 0;
	margin-top: 20px;
}

.robot-details-content h3 {
	color: #3fecc6;
	font-size: 91px;
	font-weight: bold;
}

.robot-details-content h3 span {
	font-size: 57px;
	font-weight: 300;
	color: #0269ec;
}

.robot-details-thumbnail {
	border: 5px solid #100080;
	padding: 15px;
}

.pick-a-name::placeholder {
	height: 100% !important;
	font-size: 63px;
	color: #0269ec !important;
	font-weight: 300 !important;
}

.robot-details-text input {
	font-size: 63px;
	color: #0269ec;
	font-weight: 300;
	border-bottom: 3px solid #0269ec;
	margin-bottom: 30px;
	background-color: transparent;
	border: none;
	border-bottom: 3px solid;
}

.robot-details-text input::placeholder {
	color: #0269ec;
}

/* robot details alert message */
.robot-details-alert {
	margin-top: -36px;
}

@media only screen and (max-width: 991px) {
	.robot-details-alert {
		margin-top: 36px;
	}
}

.id-search {
	font-size: 33px;
	color: #0269ec;
	font-weight: 300;
	border-bottom: 3px solid #0269ec;
	margin-bottom: 30px;
	background-color: transparent;
	border: none;
	border-bottom: 3px solid;
	width: 130px;
	text-align: center;
}

.id-search::placeholder {
	color: #0269ec;
	font-size: 25px;
}

.robot-details-text a.bordered-button {
	text-align: center;
	font-size: 61px;
}
.robot-details-text button.bordered-button {
	text-align: center;
	font-size: 61px;
}

.robot-details-text a.bordered-button {
	text-align: center;
	font-size: 61px;
	border: 7px solid;
	text-transform: capitalize;
	font-weight: 500;
}
.robot-details-text button.bordered-button {
	text-align: center;
	font-size: 61px;
	border: 7px solid;
	text-transform: capitalize;
	font-weight: 500;
}

.robot-details-text a.bordered-button.pink {
	width: 100%;
	padding: 0;
	border-color: #fc4ba3;
	box-shadow: 0 0 10px #fc4ba3;
	margin-bottom: 25px;
}
.robot-details-text button.bordered-button.pink {
	width: 100%;
	padding: 0;
	border-color: #fc4ba3;
	box-shadow: 0 0 10px #fc4ba3;
	margin-bottom: 25px;
	background-color: #022061;
}
.robot-details-text button.bordered-button.gold {
	width: 100%;
	padding: 0;
	border-color: #fc4ba3;
	box-shadow: 0 0 10px #fc4ba3;
	margin-bottom: 25px;
	background-color: #a1a003;
}
input.loading-text {
	background-color: #3fecc6;
	color: #fc4ba3;
	border-radius: 10px;
}

button.bordered-button.pink {
	border-color: #fc4ba3;
	box-shadow: 0 0 10px #fc4ba3;
	margin-bottom: 25px;
	background-color: #022061;
}
button.bordered-button.gold {
	border-color: #fc4ba3;
	box-shadow: 0 0 10px #fc4ba3;
	margin-bottom: 25px;
	background-color: #a1a003;
}

.naming-price {
	color: #3fecc6;
}

.robot-details-text a.bordered-button.green {
	width: 100%;
	padding: 0;
	border-color: #3fecc6;
	color: #fc4ba3;
	box-shadow: 0 0 10px #3fecc6;
}
.robot-details-text button.bordered-button.green {
	width: 100%;
	padding: 0;
	border-color: #3fecc6;
	color: #fc4ba3;
	box-shadow: 0 0 10px #3fecc6;
}

.robot-details-data {
	margin: 30px 0;
	text-align: center;
}

.robot-details-data p:first-child {
	font-size: 50px !important;
	font-weight: bold;
}
.robot-details-content button.bordered-button {
	background-color: #022061;
}

.robot-details-data p {
	font-size: 45px;
	color: #fff;
}

.robot-details-data p span {
	display: inline-block;
	margin-left: 15px;
	font-weight: bold;
}

.robot-details-data p {
	margin-bottom: 0;
}

/* .robot-details-text {
   margin-left: 50px; 
} */

.robot-details-shape {
	right: 0;
	top: 0;
}
.etherscanlink {
	margin-bottom: 25px;
}
.etherscanlink a {
	color: #fc4ba3;
	font-size: 20px;
}

section.earn-section {
	padding-top: 460px;
}

.namehistory-table {
	/* margin: auto; */
	width: 80%;
	/* margin-top: 100px; */
}

.namehistory-table tr,
td,
tbody {
	color: #3fecc6;
	border: none;
	font-size: 25px;
	font-weight: lighter;
}

.namehistory-th {
	text-align: center;
	border: 0;
	border-collapse: collapse;
	font-weight: lighter;
}

.earn-content h1 {
	color: #3fecc6;
	font-size: 71px;
	font-weight: bold;
}

.earn-content h1 {
	border-bottom: 5px solid #fc4ba3;
	padding-bottom: 10px;
}

.earn-content p {
	font-size: 45px;
	color: #0269ec;
	margin-top: 15px;
}

.earn-content .bordered-button {
	color: #fc4ba3;
	border-color: #3fecc6;
	background-image: linear-gradient(90deg, #022061, #030a35);
	font-size: 55px;
	width: 60%;
	border-radius: 100px;
	margin-top: 20px;
	box-shadow: 0 0 10px #022262;
	font-weight: 500;
}

.earn-content.text-center {
	margin-bottom: 100px;
}

.earn-card {
	border: 3px solid #0269ec;
	margin: 0 30px;
	border-radius: 17px;
	padding: 50px;
	position: relative;
}

.earn-card .badge {
	position: absolute;
	top: -40px;
	left: 50%;
	font-size: 44px;
	display: inline-block;
	margin: 0 auto;
	transform: translateX(-50%);
	background-color: #0269ec;
	border-radius: 50px;
	padding: 13px 39px;
	color: #000000;
	min-width: 50%;
}

.earn-component p.text-green,
.earn-component p.text-pink {
	font-size: 45px;
	font-weight: 500;
	margin-bottom: 0;
}

.earn-component p:last-child {
	color: #0269ec;
	font-size: 32px;
	font-weight: bold;
	margin-bottom: 0;
}

span.black-underline {
	display: block;
	background-color: #000000;
	width: 100%;
	height: 4px;
}

.earn-component {
	margin-bottom: 20px;
}

section.earn-section {
	background-repeat: no-repeat;
	background-position: 50% 135%;
	background-size: contain;
}

.earn-card {
	background-image: linear-gradient(45deg, #02205f, transparent);
}

.earn-buttons a {
	text-align: center;
	display: block;
	width: 90%;
	margin: 0 auto;
	margin-bottom: 15px;
}

.earn-buttons a:last-child {
	margin-bottom: 0;
}

.earn-card.first-card {
	padding-top: 160px;
}

.earn-card.first-card {
	position: relative;
}

.earn-card.first-card:after {
	position: absolute;
	right: -46px;
	top: 0;
	width: 3px;
	height: 100%;
	content: "";
	background-color: #0269ec;
}

.earn-buttons a {
	font-size: 34px;
}

.earn-buttons a {
	font-size: 34px;
	text-transform: capitalize;
}

.earn-buttons a.bordered-button:first-child {
	border-color: #fc4ba3;
	padding: 5px 0;
	box-shadow: 0 0 10px #fc4ba3;
	margin-bottom: 20px;
}
.earn-buttons button.bordered-button:first-child {
	border-color: #fc4ba3;
	padding: 5px 0;
	box-shadow: 0 0 10px #fc4ba3;
	margin-bottom: 20px;
}

.earn-buttons a.bordered-button:last-child {
	border-color: #3fecc6;
	padding: 5px 0;
	box-shadow: 0 0 10px #3fecc6;
	color: #fc4ba3;
}
.earn-buttons button.bordered-button:last-child {
	border-color: #3fecc6;
	padding: 5px 0;
	box-shadow: 0 0 10px #3fecc6;
	color: #fc4ba3;
}
section.earn-section {
	margin-bottom: 100px;
}

.home-button {
	margin-top: 0px;
	max-height: 40px;
	width: 170px !important;
}

/* .bordered-button.pink-border {
} */

img.earn-shape-left {
	left: 0;
}

img.earn-shape-right {
	right: 0;
}

.robot-details-content {
	position: relative;
	z-index: 2;
}

.earn-content.text-center {
	position: relative;
	z-index: 2;
}

.lets-browse-search-text h1 {
	font-size: 60px;
	margin-bottom: 20px;
}

.lets-browse-search-text input[type="search"] {
	font-size: 30px;
}

.pagination-robots button,
.pagination-robots span {
	font-size: 1rem;
	width: 45px;
	height: 45px;
	line-height: 37px;
	background: none;
}

.pagination-robots-link {
	font-size: 25px;
	width: 45px;
	height: 45px;
	line-height: 37px;
}

.pagination-active-link a {
	color: #100080;
	border: 4px solid #100080;
	display: none;
}

.pagination-li {
	list-style-type: none;
	padding: 0;
	margin: -5px;
	display: none;
}

.pagination-li a {
	width: 80px;
	user-select: none;
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-o-user-select: none;
}

.pagination-prev-li {
	list-style-type: none;
	padding: 0;
	margin: -5px;
	display: inline-block;
	background: url("http://www.nftr.name/img/pagination-prev.png") no-repeat
		bottom center;
	background-size: 35px 35px;
}

.pagination-next-li {
	list-style-type: none;
	padding: 0;
	margin: -5px;
	display: inline-block;
	background: url("http://www.nftr.name/img/pagination-next.png") no-repeat
		bottom center;
	background-size: 35px 35px;
}

.invite-content h1 {
	font-size: 50px;
}

.invite-content h2 {
	font-size: 20px;
	color: #fff;
}

.invite-content input[type="text"] {
	font-size: 98px;
	line-height: 49px;
}

.invite-content button {
	font-size: 30px;
}

.golden-ticket-content p {
	font-size: 21px;
}

.golden-ticket-content img {
	max-width: 70%;
}

.golden-ticket-content a.bordered-button {
	font-size: 35px;
}
.golden-ticket-content button.bordered-button {
	font-size: 35px;
}

.golden-ticket-ratio-content p {
	font-size: 25px;
}

button.bordered-button.blue-ticket-btn {
	font-size: 35px;
}
button.bordered-button.blue-ticket-btn {
	font-size: 35px;
}
button.bordered-button.yello-ticket-btn {
	font-size: 35px;
}
button.bordered-button.yello-ticket-btn {
	font-size: 35px;
}

.golden-ticket-box p {
	font-size: 25px;
}

.earn-content h1 {
	font-size: 75px;
}

.earn-content p {
	font-size: 30px;
}

.earn-content .bordered-button {
	font-size: 30px;
	width: 30%;
}

.earn-component p.text-green,
.earn-component p.text-pink {
	font-size: 30px;
}

.earn-component p:last-child {
	font-size: 25px;
}

.earn-card .badge {
	font-size: 25px;
	top: -30px;
}

.robot-details-content h3 {
	font-size: 50px;
}

p.robot-id {
	font-size: 30px;
}

.robot-details-data p {
	font-size: 25px;
}

.robot-details-text a.bordered-button {
	font-size: 40px;
}
.robot-details-text button.bordered-button {
	font-size: 40px;
}

.header-two .navbar-nav a {
	font-size: 18px;
}
.header-two .navbar-nav button {
	font-size: 18px;
}

.mr-2 {
	margin-right: 20px;
}

span.find-by-filters {
	margin-left: 20px;
}

span.find-by-filters span {
	font-weight: 700;
}

span.find-by-filters span.active {
	color: #fc4ba3;
}

span.find-by-filters span {
	display: inline-block;
	margin: 0 8px;
	cursor: pointer;
}

.earn-buttons a:first-child:hover {
	background-color: #fc4ba3;
}

section.earn-section {
	padding-top: 50px;
}

a.bordered-button.pink:hover {
	background-color: #fc4ba3;
}
button.bordered-button.pink:hover {
	background-color: #fc4ba3;
}
button.bordered-button.gold:hover {
	background-color: #c1c403;
}

.robot-details-data p:first-child {
	font-size: 25px !important;
	margin-bottom: 5px;
}

/* div#wallet img {
	max-width: 35px;
} */

.custom-modal {
	margin-top: 80px;
}

.modal-header-naming {
	/* background-color: #c1c403; */
	/* color: #030a34; */
}

.modal-button-naming-yes {
	background: #c1c403;
	color: #030a34;
}

.modal-button-naming-no {
	color: #030a34;
}

.modal-body-naming {
	color: #030a34;
}

.wallet-container {
	margin-top: 10px;
	z-index: 22;
	position: relative;
}

section.robot-details {
	padding-top: 50px;
}

p.robot-id,
.robot-name {
	font-size: 30px;
}

.robot-details-content .navigation {
	font-size: 35px;
}

.robot-details-content h3 {
	font-size: 31px;
	/* margin-bottom: 30px; */
}

.robot-details-content h3 span {
	font-size: 43px;
}

.robot-details-thumbnail {
	max-width: 450px;
	margin-top: 15px;
	margin-bottom: 15px;
}

.robot-details-text input {
	width: 100%;
}

.robot-details-wrapper a.bordered-button.green {
	text-align: center;
}
.robot-details-wrapper button.bordered-button.green {
	text-align: center;
}

.robot-details-wrapper {
	text-align: center;
}

/* nft external link */

.marketplace {
	margin-top: 24px;
	margin-left: 2px;
	justify-content: flex-start;
}

.marketplace .col {
	max-width: 50px;
	padding: 0;
}

.marketplace-logo {
	width: 30px;
	height: 30px;
}

/* Robot details button */
.robot-details-buttons {
	width: 450px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.robot-details-buttons {
		width: 360px;
	}
}

@media only screen and (max-width: 476px) {
	.robot-details-buttons {
		width: 360px;
	}
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-notification {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: fixed;
	z-index: 1010;
	margin-right: 24px;
}
.ant-notification-topLeft,
.ant-notification-bottomLeft {
	margin-right: 0;
	margin-left: 24px;
}
.ant-notification-topLeft
	.ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-bottomLeft
	.ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-topLeft
	.ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-bottomLeft
	.ant-notification-fade-appear.ant-notification-fade-appear-active {
	-webkit-animation-name: NotificationLeftFadeIn;
	animation-name: NotificationLeftFadeIn;
}
.ant-notification-close-icon {
	font-size: 14px;
	cursor: pointer;
}
.ant-notification-hook-holder {
	position: relative;
}
.ant-notification-notice {
	position: relative;
	width: 384px;
	max-width: calc(100vw - 24px * 2);
	margin-bottom: 16px;
	margin-left: auto;
	padding: 16px 24px;
	overflow: hidden;
	line-height: 1.5715;
	word-wrap: break-word;
	background: #fff;
	border-radius: 2px;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-notification-topLeft .ant-notification-notice,
.ant-notification-bottomLeft .ant-notification-notice {
	margin-right: auto;
	margin-left: 0;
}
.ant-notification-notice-message {
	margin-bottom: 8px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 16px;
	line-height: 24px;
}
.ant-notification-notice-message-single-line-auto-margin {
	display: block;
	width: calc(384px - 24px * 2 - 24px - 48px - 100%);
	max-width: 4px;
	background-color: transparent;
	pointer-events: none;
}
.ant-notification-notice-message-single-line-auto-margin::before {
	display: block;
	content: "";
}
.ant-notification-notice-description {
	font-size: 14px;
}
.ant-notification-notice-closable .ant-notification-notice-message {
	padding-right: 24px;
}
.ant-notification-notice-with-icon .ant-notification-notice-message {
	margin-bottom: 4px;
	margin-left: 48px;
	font-size: 16px;
}
.ant-notification-notice-with-icon .ant-notification-notice-description {
	margin-left: 48px;
	font-size: 14px;
}
.ant-notification-notice-icon {
	position: absolute;
	margin-left: 4px;
	font-size: 24px;
	line-height: 24px;
}
.anticon.ant-notification-notice-icon-success {
	color: #52c41a;
}
.anticon.ant-notification-notice-icon-info {
	color: #1890ff;
}
.anticon.ant-notification-notice-icon-warning {
	color: #faad14;
}
.anticon.ant-notification-notice-icon-error {
	color: #ff4d4f;
}
.ant-notification-notice-close {
	position: absolute;
	top: 16px;
	right: 22px;
	color: rgba(0, 0, 0, 0.45);
	outline: none;
}
.ant-notification-notice-close:hover {
	color: rgba(0, 0, 0, 0.67);
}
.ant-notification-notice-btn {
	float: right;
	margin-top: 16px;
}
.ant-notification .notification-fade-effect {
	-webkit-animation-duration: 0.24s;
	animation-duration: 0.24s;
	-webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}
.ant-notification-fade-enter,
.ant-notification-fade-appear {
	-webkit-animation-duration: 0.24s;
	animation-duration: 0.24s;
	-webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	opacity: 0;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}
.ant-notification-fade-leave {
	-webkit-animation-duration: 0.24s;
	animation-duration: 0.24s;
	-webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-duration: 0.2s;
	animation-duration: 0.2s;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}
.ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-fade-appear.ant-notification-fade-appear-active {
	-webkit-animation-name: NotificationFadeIn;
	animation-name: NotificationFadeIn;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}
.ant-notification-fade-leave.ant-notification-fade-leave-active {
	-webkit-animation-name: NotificationFadeOut;
	animation-name: NotificationFadeOut;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}
@-webkit-keyframes NotificationFadeIn {
	0% {
		left: 384px;
		opacity: 0;
	}
	100% {
		left: 0;
		opacity: 1;
	}
}
@keyframes NotificationFadeIn {
	0% {
		left: 384px;
		opacity: 0;
	}
	100% {
		left: 0;
		opacity: 1;
	}
}
@-webkit-keyframes NotificationLeftFadeIn {
	0% {
		right: 384px;
		opacity: 0;
	}
	100% {
		right: 0;
		opacity: 1;
	}
}
@keyframes NotificationLeftFadeIn {
	0% {
		right: 384px;
		opacity: 0;
	}
	100% {
		right: 0;
		opacity: 1;
	}
}
@-webkit-keyframes NotificationFadeOut {
	0% {
		max-height: 150px;
		margin-bottom: 16px;
		opacity: 1;
	}
	100% {
		max-height: 0;
		margin-bottom: 0;
		padding-top: 0;
		padding-bottom: 0;
		opacity: 0;
	}
}
@keyframes NotificationFadeOut {
	0% {
		max-height: 150px;
		margin-bottom: 16px;
		opacity: 1;
	}
	100% {
		max-height: 0;
		margin-bottom: 0;
		padding-top: 0;
		padding-bottom: 0;
		opacity: 0;
	}
}
.ant-notification-rtl {
	direction: rtl;
}
.ant-notification-rtl
	.ant-notification-notice-closable
	.ant-notification-notice-message {
	padding-right: 0;
	padding-left: 24px;
}
.ant-notification-rtl
	.ant-notification-notice-with-icon
	.ant-notification-notice-message {
	margin-right: 48px;
	margin-left: 0;
}
.ant-notification-rtl
	.ant-notification-notice-with-icon
	.ant-notification-notice-description {
	margin-right: 48px;
	margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-icon {
	margin-right: 4px;
	margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-close {
	right: auto;
	left: 22px;
}
.ant-notification-rtl .ant-notification-notice-btn {
	float: left;
}

@media all and (max-width: 767px) {
	.letsbrowse-contractaddress-link {
		font-size: 12px;
	}
	.react-select-label {
		font-size: 15px;
		position: relative;
		right: -20px;
	}
	.react-select {
		padding: 20px;
	}
}

.naming-warning {
	margin-top: 15px;
}

.ant-radio-group {
	box-sizing: border-box;
	margin: 0;
	margin-top: 10px;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	display: inline-block;
	font-size: 0;
}
.ant-radio-group .ant-badge-count {
	z-index: 1;
}
.ant-radio-group > .ant-badge:not(:first-child) > .ant-radio-button-wrapper {
	border-left: none;
}
.ant-radio-wrapper {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: relative;
	display: inline-flex;
	align-items: baseline;
	margin-right: 8px;
	cursor: pointer;
}
.ant-radio-wrapper-disabled {
	cursor: not-allowed;
}
.ant-radio-wrapper::after {
	display: inline-block;
	width: 0;
	overflow: hidden;
	content: "\a0";
}
.ant-radio-wrapper.ant-radio-wrapper-in-form-item input[type="radio"] {
	width: 14px;
	height: 14px;
}
.ant-radio {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: relative;
	top: 0.2em;
	display: inline-block;
	outline: none;
	cursor: pointer;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
	border-color: #fc4ba3;
}
/* .ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px #e6f7ff;
} */
.ant-radio-checked::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 1px solid #fc4ba3;
	color: #030a34;
	/* background-color: #022061;
  background: #022061; */
	border-radius: 50%;
	visibility: hidden;
	-webkit-animation: antRadioEffect 0.36s ease-in-out;
	animation: antRadioEffect 0.36s ease-in-out;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	content: "";
}
.ant-radio:hover::after,
.ant-radio-wrapper:hover .ant-radio::after {
	visibility: visible;
}
.ant-radio-inner {
	position: relative;
	top: 0;
	left: 0;
	display: block;
	width: 16px;
	height: 16px;
	background-color: #fff;
	border-color: #fc4ba3;
	border-style: solid;
	border-width: 1px;
	border-radius: 50%;
	transition: all 0.3s;
}
.ant-radio-inner::after {
	position: absolute;
	top: 50%;
	left: 50%;
	display: block;
	width: 16px;
	height: 16px;
	margin-top: -8px;
	margin-left: -8px;
	background-color: #fc4ba3;
	border-top: 0;
	border-left: 0;
	border-radius: 16px;
	transform: scale(0);
	opacity: 0;
	transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
	content: " ";
}
.ant-radio-input {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	cursor: pointer;
	opacity: 0;
}
.ant-radio-checked .ant-radio-inner {
	border-color: #fc4ba3;
}
.ant-radio-checked .ant-radio-inner::after {
	transform: scale(0.5);
	opacity: 1;
	transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-radio-disabled {
	cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner {
	background-color: #fc4ba3;
	border-color: #fc4ba3 !important;
	cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner::after {
	background-color: rgba(0, 0, 0, 0.2);
}
.ant-radio-disabled .ant-radio-input {
	cursor: not-allowed;
}
.ant-radio-disabled + span {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}
span.ant-radio + * {
	padding-right: 8px;
	padding-left: 8px;
}
.ant-radio-button-wrapper {
	position: relative;
	display: inline-block;
	height: 32px;
	margin: 0;
	padding: 0 15px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	line-height: 30px;
	background: transparent;
	border: 1px solid #fc4ba3;
	border-top-width: 1.02px;
	border-left-width: 0;
	cursor: pointer;
	/* transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;*/
}
.ant-radio-button-wrapper a {
	color: rgba(0, 0, 0, 0.85);
}
.ant-radio-button-wrapper > .ant-radio-button {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
}
.ant-radio-group-large .ant-radio-button-wrapper {
	height: 40px;
	font-size: 16px;
	line-height: 38px;
}
.ant-radio-group-small .ant-radio-button-wrapper {
	height: 24px;
	padding: 0 7px;
	line-height: 22px;
}
.ant-radio-button-wrapper:not(:first-child)::before {
	position: absolute;
	top: -1px;
	left: -1px;
	display: block;
	box-sizing: content-box;
	width: 1px;
	height: 100%;
	padding: 1px 0;
	background-color: #fc4ba3;
	transition: background-color 0.3s;
	content: "";
}
.ant-radio-button-wrapper:first-child {
	border-left: 1px solid #fc4ba3;
	border-radius: 20px 0 0 20px;
}
.naming-currency-radio + .right {
	border-radius: 0 20px 20px 0 !important;
}
.naming-currency-radio + .middle {
	border-radius: 0 0 0 0 !important;
}
.naming-currency-radio + .left {
	border-radius: 20px 0 0 20px !important;
}
.naming-currency-radio.left {
	border-radius: 20px 0 0 20px !important;
}
.left .ant-radio-button {
	border-radius: 20px 0 0 20px;
}
.ant-radio-button-checked {
	/* animation-name: naming-currency-radio;
  animation-duration: 1s; */
	background: transparent;
}
/* background: linear-gradient(#3fecc6, #fc4ba3); */
.right .ant-radio-button {
	border-radius: 0 10px 10px 0;
}
/*
label.naming-currency-radio span {
  color: #18007f;
}
*/
/* @keyframes naming-currency-radio {
  from {
    background: linear-gradient(#3fecc6, white);
  }
  to {
    background: linear-gradient(#3fecc6, #fc4ba3);
  }
} */

/*
#fc4ba3 pink 
#3fecc6 teal
#18007f dark blue
*/
.ant-radio-button-wrapper {
	background: transparent;
	font-size: 20px;
	color: #fc4ba3;
}
/* .ant-radio-button-wrapper:last-child {
  border-radius: 0 10px 10px 0;
} */
/* .ant-radio-button-wrapper:first-child:last-child {
  border-radius: 2px;
} */
.ant-radio-button-wrapper:hover {
	position: relative;
	color: #fc4ba3;
}
/* .ant-radio-button-wrapper:focus-within {
  box-shadow: 0 0 0 3px #e6f7ff;
} */
.ant-radio-button-wrapper .ant-radio-inner,
.ant-radio-button-wrapper input[type="checkbox"],
.ant-radio-button-wrapper input[type="radio"] {
	width: 0;
	height: 0;
	opacity: 0;
	pointer-events: none;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
	z-index: 1;
	color: #030a34;
	background: #fc4ba3;
	border-color: #fc4ba3;
}
.ant-radio-button-wrapper-checked:not(
		.ant-radio-button-wrapper-disabled
	)::before {
	color: #030a34;
	background-color: #fc4ba3;
}
.ant-radio-button-wrapper-checked:not(
		.ant-radio-button-wrapper-disabled
	):first-child {
	color: #030a34;
	border-color: #fc4ba3;
}
.ant-radio-button-wrapper-checked:not(
		.ant-radio-button-wrapper-disabled
	):hover {
	color: #030a34;
	border-color: #fc4ba3;
}
.ant-radio-button-wrapper-checked:not(
		.ant-radio-button-wrapper-disabled
	):hover::before {
	color: #030a34;
	background-color: #fc4ba3;
}
.ant-radio-button-wrapper-checked:not(
		.ant-radio-button-wrapper-disabled
	):active {
	color: #030a34;
	border-color: #fc4ba3;
}
.ant-radio-button-wrapper-checked:not(
		.ant-radio-button-wrapper-disabled
	):active::before {
	color: #030a34;
	background-color: #fc4ba3;
}
/* .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px #e6f7ff;
} */
.ant-radio-group-solid
	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
	color: #18007f;
	background: #fc4ba3;
	border-color: #fc4ba3;
}
.ant-radio-group-solid
	.ant-radio-button-wrapper-checked:not(
		.ant-radio-button-wrapper-disabled
	):hover {
	color: #18007f;
	background: #fc4ba3;
	border-color: #fc4ba3;
}
.ant-radio-group-solid
	.ant-radio-button-wrapper-checked:not(
		.ant-radio-button-wrapper-disabled
	):active {
	color: #18007f;
	background: #fc4ba3;
	border-color: #fc4ba3;
}
/* .ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px #e6f7ff;
} */
.ant-radio-button-wrapper-disabled {
	color: rgba(0, 0, 0, 0.25);
	background-color: #fc4ba3;
	border-color: #fc4ba3;
	cursor: not-allowed;
}
.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	border-color: #fc4ba3;
}
.ant-radio-button-wrapper-disabled:first-child {
	border-left-color: #fc4ba3;
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
	color: rgba(0, 0, 0, 0.25);
	background-color: #fc4ba3;
	border-color: #fc4ba3;
	box-shadow: none;
}
@-webkit-keyframes antRadioEffect {
	0% {
		transform: scale(1);
		opacity: 0.5;
	}
	100% {
		transform: scale(1.6);
		opacity: 0;
	}
}
@keyframes antRadioEffect {
	0% {
		transform: scale(1);
		opacity: 0.5;
	}
	100% {
		transform: scale(1.6);
		opacity: 0;
	}
}
.ant-radio-group.ant-radio-group-rtl {
	direction: rtl;
}
.ant-radio-wrapper.ant-radio-wrapper-rtl {
	margin-right: 0;
	margin-left: 8px;
	direction: rtl;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl {
	border-right-width: 0;
	border-left-width: 1px;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:not(
		:first-child
	)::before {
	right: -1px;
	left: 0;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:first-child {
	border-right: 1px solid #fc4ba3;
	border-radius: 0 10px 10px 0;
}
.ant-radio-button-wrapper-checked:not(
		[class*=" ant-radio-button-wrapper-disabled"]
	).ant-radio-button-wrapper:first-child {
	border-right-color: #fc4ba3;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:last-child {
	border-radius: 10px 0 0 10px;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper-disabled:first-child {
	border-right-color: #d9d9d9;
}
#num-tickets-mint,
#num-tickets-burn {
	user-select: none; /* supported by Chrome and Opera */
	-webkit-user-select: text; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	outline: none;
}

.pick-a-name {
	user-select: none; /* supported by Chrome and Opera */
	-webkit-user-select: text; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	outline: none;
}

.screen-blocker-div {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 2147483647;
	border: none;
}

.loader-div {
	position: absolute;
	left: calc(50% - 40px);
	top: calc(50% - 40px);
}
.loader svg {
	fill: #fc4ba3;
}

p.earn-content-desc {
	color: #fff;
	font-size: 44px;
	margin-top: 70px;
}

.earn-content .earn-buttons a {
	display: inline-block;
	color: #fff;
	font-size: 20px;
	width: auto;
	text-decoration: none;
	border: 6px solid #3fecc6;
	border-radius: 75px;
	padding: 20px 62px;
	box-shadow: 0 0 20px #3fecc6;
	transition: 0.5s;
	width: 322px;
}

.earn-content .earn-buttons a i {
	margin-left: 10px;
}

.earn-content .earn-buttons a {
	margin: 0 25px;
}

.earn-content .earn-buttons {
	margin-top: 72px;
}

.earn-content .earn-buttons a:hover {
	background-color: #3fecc6;
	color: #333;
}

.earn-yield-tab-section ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.earn-yield-tab-section ul li a {
	color: #fff;
	text-decoration: none;
	font-size: 20px;
}

.earn-yield-tab-section ul {
	display: flex;
	justify-content: center;
	border-bottom: 2px solid #898da0;
	padding-bottom: 5px;
	margin-bottom: 50px;
}

.earn-yield-tab-section ul li {
	margin: 0 35px;
}

.earn-yield-tab-section ul li a.active {
	position: relative;
}

.earn-yield-tab-section ul li a.active:after {
	position: absolute;
	bottom: -8px;
	left: 0;
	width: 100%;
	height: 4px;
	background-color: #3fecc6;
	content: "";
	border-radius: 50px;
}

.earn-yield-tab-section .tab-content-wrapper {
	background-image: linear-gradient(90deg, #013a9b78, #1b3f6159);
	border: 3px solid #3eecc6;
	border-radius: 25px;
	overflow: hidden;
	padding: 50px;
	box-shadow: 0 0 20px #3fecc6;
}

.para-medium {
	color: #fff;
	font-size: 18px;
}

.para-small {
	color: #fff;
	opacity: 0.65;
	font-size: 13px;
}

.para-large {
	color: #fff;
	font-size: 18px;
}

.tab-left-content p .tooltip {
	margin-left: 5px;
}

.tab-left-content h4 {
	color: #3eecc6;
	font-size: 25px;
	margin-bottom: 20px;
}

button.accordion-button {
	background-color: transparent !important;
}

.accordion-item {
	background-color: transparent !important;
	border: none;
}

.accordion-collapse {
	background-color: #030a34;
}

.accordion-header-left h4 {
	color: #3eecc6;
	font-size: 25px;
}

.accordion-button::after {
	filter: brightness(0) invert(1);
}

.accordion-header-left h4 span {
	color: #fff;
	font-size: 20px;
}

.accordion-header-left h4 {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	display: inline-block;
}

.accordion-header-left {
	width: 100%;
}

.accordion-header-left h4 {
	display: flex;
	justify-content: space-between;
}

.accordion-header-left h4 span {
	color: #3fecc6;
	font-size: 25px;
}

.accordion-header-left h4 span.accordion-header-right {
	color: #fff;
	font-size: 20px;
	padding-right: 18px;
}

.accordion-button::after {
	margin-bottom: 10px;
}

.accordion-button:not(.collapsed) {
	box-shadow: none;
}

.accordion-button:focus {
	box-shadow: none !important;
}

.accordion-navigation {
	border: 1px solid #1f2642;
	width: auto !important;
	padding: 7px;
	border-radius: 8px;
	display: inline-flex;
	margin-bottom: 40px;
}

.accordion-navigation .active {
	background-color: #fff !important;
	color: #333 !important;
}

.accordion-navigation a {
	color: #fff;
}

.accordion-navigation .nav-item a {
	padding-left: 40px;
	padding-right: 40px;
	cursor: pointer;
	padding-top: 5px;
	padding-bottom: 5px;
}
.accordion-navigation .nav-item a:hover {
	color: #fff;
}

.accordion-body {
	padding: 30px;
	/* background: none; */
	/* background-color: transparent; */
}

.tab-content .form-group {
	display: flex;
}

.tab-content .form-group input {
	background-color: #1d2349;
	border: none;
	padding: 15px 20px;
	border-radius: 0;
}

.tab-content .form-group button {
	background-color: #1d2349;
	border: none;
	color: #a0a0a0;
	padding: 0 20px;
}

.tab-content .form-group input:focus {
	outline: none;
	box-shadow: none;
}

.notification {
	color: #ec3730;
}

.accordion-body .notification {
	margin-top: 10px;
}

.tab-content .form-group input {
	color: #fff;
}

input.show-error {
	border: 1px solid red !important;
}

button:disabled {
	opacity: 0.3;
}

.statistics-row {
	display: flex;
	justify-content: space-between;
	color: #a0a0a0;
	margin-top: 15px;
	font-size: 18px;
}

.statistics {
	margin-top: 20px;
}

.statistics-bottom h4 {
	color: #5f5f5f;
	font-size: 18px;
	margin-bottom: 0;
}

.statistics-bottom {
	margin-top: 30px;
	border-top: 1px solid;
	padding-top: 30px;
}

.textbox-space-between {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.textbox-space-between h5 {
	color: #fff;
	font-size: 13px;
	opacity: 0.65;
}

.textbox-space-between p {
	margin-bottom: 0;
	color: #fff;
	opacity: 0.65;
	font-size: 20px;
}

.textbox-space-between p span {
	margin-left: 10px;
}

.form-group-input {
	position: relative;
}

p.placeholder-input {
	position: absolute;
	left: 36px;
	top: 50%;
	margin: 0;
	transform: translateY(-50%);
	color: #fff;
}

.form-group-input .placeholder-wrapper {
	border: none;
	background-color: transparent;
	padding: 18px;
	width: 100%;
	border: 2px solid #fff;
	border-radius: 50px;
	outline: none;
	z-index: 2;
	color: #fff;
	position: relative;
}

.form-group-input .placeholder-wrapper {
	padding: 30px;
}

.form-group-input input[type="submit"] {
	top: 0;
}

.form-group-input input[type="submit"] {
	background-color: #3fecc6;
	padding: 17px 18px;
	border: none;
	width: 26%;
	border-radius: 50px;
	font-size: 20px;
	position: absolute;
	right: 0;
	z-index: 3;
}

p.placeholder-input span {
	color: #ffc73d;
	font-size: 80%;
}

.pink-text {
	color: #ff7bc3;
}

.mt-40 {
	margin-top: 40px;
}

.mb-40 {
	margin-bottom: 40px;
}

.mt-30 {
	margin-top: 30px;
}

.mb-30 {
	margin-bottom: 30px;
}

.mt-20 {
	margin-top: 20px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mt-10 {
	margin-top: 10px;
}

.mb-10 {
	margin-bottom: 10px;
}

.mt-5 {
	margin-top: 5px;
}

.mb-5 {
	margin-bottom: 5px;
}

.statistics-row span {
	font-size: 18px;
}

.accordion-body h6 {
	font-size: 15px;
	color: #fff;
	opacity: 0.65;
}

span.bar {
	background-color: #555761;
	width: 100%;
	display: inline-block;
	height: 1px;
}

.spend-gtk .tab-content .form-group input {
	width: 67%;
}

.accordion-naming.accordion-body {
	padding: 0;
	padding-top: 30px;
	padding-bottom: 30px;
}

.blue-box {
	padding: 0 30px;
}

.blue-box {
	color: #fff;
}

.blue-box h4 {
	font-size: 20px;
}

button.collect-btn {
	background-color: #3fecc6;
	font-size: 20px;
	display: inline-block;
	padding: 15px 40px;
	border-radius: 50px;
}

.blue-box-left p {
	margin-bottom: 0;
}

.border-top {
	border-top: 1px solid #2b2f4a !important;
}
.border-bottom {
	border-bottom: 1px solid #2b2f4a !important;
}

.blue-box-padding {
	padding-top: 20px;
	padding-bottom: 20px;
}

.blue-box-left p {
	opacity: 0.8;
}

.details-box h4,
.details-box p {
	opacity: 0.8;
}

.details-box .statistics-row span {
	opacity: 1 !important;
	color: #fff;
	padding-bottom: 30px;
}

.details-box .statistics-row {
	margin-top: 30px;
}

.blue-box .statistics-row span {
	color: #fff;
}

.statistics-row.accordion-naming-bulk-name span {
	color: #fff;
}

.statistics-row.accordion-naming-bulk-name {
	display: flex;
	align-items: center;
	margin-top: 0;
}

a.inline-btn {
	color: #3fecc6;
	text-decoration: none;
}

.statistics-row.accordion-naming-bulk-name {
	margin-bottom: 10px;
}

.earn-yield-tab-section {
	background-size: contain;
	background-position: top center;
	background-repeat: no-repeat;
}

p.placeholder-input img {
	margin-top: -3px;
	margin-right: 3px;
}

button.collect-btn {
	transition: 0.3s;
}

button.collect-btn:hover {
	opacity: 0.5;
}

.form-group-input input[type="submit"]:hover {
	opacity: 0.5;
}

.form-group-input input[type="submit"] {
	transition: 0.3s;
}

.gtk-buttons a {
	text-align: center;
	display: block;
	width: 90%;
	margin: 0 auto;
	margin-bottom: 15px;
	font-size: 24px;
	font-size: 24px;
	text-transform: capitalize;
}

.gtk-buttons a:last-child {
	margin-bottom: 0;
}

.gtk-buttons a.bordered-button:first-child {
	border-color: #fc4ba3;
	padding: 5px 0;
	box-shadow: 0 0 10px #fc4ba3;
	margin-bottom: 20px;
}
.gtk-buttons button.bordered-button:first-child {
	border-color: #fc4ba3;
	padding: 5px 0;
	box-shadow: 0 0 10px #fc4ba3;
	margin-bottom: 20px;
	background-color: #022061;
}

.gtk-buttons a.bordered-button:last-child {
	border-color: #c1c403;
	padding: 5px 0;
	box-shadow: 0 0 10px #c1c403;
	color: #fc4ba3;
}
.gtk-buttons button.bordered-button:last-child {
	border-color: #c1c403;
	padding: 5px 0;
	box-shadow: 0 0 10px #c1c403;
	color: #fc4ba3;
	background-color: #022061;
}

.earn-content .bordered-button {
	color: #fc4ba3;
	border-color: #c1c403;
	background-image: linear-gradient(90deg, #022061, #030a35);
	font-size: 55px;
	width: 60%;
	border-radius: 100px;
	margin-top: 20px;
	box-shadow: 0 0 10px #022262;
	font-weight: 500;
}

.earn-content .gtk-buttons a {
	display: inline-block;
	color: #fff;
	font-size: 20px;
	width: auto;
	text-decoration: none;
	border: 6px solid #c1c403;
	border-radius: 75px;
	padding: 5px 25px;
	box-shadow: 0 0 20px #c1c403;
	transition: 0.5s;
	width: 250px;
	height: 50px;
}

.earn-content .gtk-buttons a i {
	margin-left: 10px;
}

.earn-content .gtk-buttons a {
	margin: 0 25px;
}

.earn-content .gtk-buttons {
	margin-top: 22px;
}

.earn-content .gtk-buttons a:hover {
	background-color: #c1c403;
	color: #333;
}

.change-page {
	filter: brightness(120);
}

/* retweet button */
.retweet-button {
	text-decoration: none;
	padding: 4px 64px;
	border-radius: 16px;
	color: #ffff;
	font-weight: 500;
}

/* tooltip */
.Tooltip-Wrapper {
	display: inline-block;
	position: relative;
}

/* Absolute positioning */
.Tooltip-Tip {
	position: absolute;
	border-radius: 4px;
	left: 50%;
	transform: translateX(-50%);
	padding: 6px;
	color: var(--tooltip-text-color);
	background: var(--tooltip-background-color);
	font-size: 14px;
	font-family: sans-serif;
	line-height: 1;
	z-index: 100;
	white-space: nowrap;
}

/* CSS border triangles */
.Tooltip-Tip::before {
	content: " ";
	left: 50%;
	border: solid transparent;
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-width: var(--tooltip-arrow-size);
	margin-left: calc(var(--tooltip-arrow-size) * -1);
}

/* Absolute positioning */
.Tooltip-Tip.top {
	top: calc(var(--tooltip-margin) * -1);
}
/* CSS border triangles */
.Tooltip-Tip.top::before {
	top: 100%;
	border-top-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.right {
	left: calc(100% + var(--tooltip-margin));
	top: 50%;
	transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.Tooltip-Tip.right::before {
	left: calc(var(--tooltip-arrow-size) * -1);
	top: 50%;
	transform: translateX(0) translateY(-50%);
	border-right-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.bottom {
	bottom: calc(var(--tooltip-margin) * -1);
}
/* CSS border triangles */
.Tooltip-Tip.bottom::before {
	bottom: 100%;
	border-bottom-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.left {
	left: auto;
	right: calc(100% + var(--tooltip-margin));
	top: 50%;
	transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.Tooltip-Tip.left::before {
	left: auto;
	right: calc(var(--tooltip-arrow-size) * -2);
	top: 50%;
	transform: translateX(0) translateY(-50%);
	border-left-color: var(--tooltip-background-color);
}

.Tooltip-Wrapper {
	border-radius: 50%;
	padding: 4px;
}

/* #opensea-tooltip {
  padding-left: 0px;
} */

/* activity table */

.activity-table th {
	font-weight: normal;
	border-bottom: 1px solid #dee2e6 !important;
}

#activity-table {
	border-collapse: separate;
}

.cancel-offer {
	text-align: center;
}

@media only screen and (max-width: 768px) {
	.accordion-body {
		padding: 0 !important;
	}
	td,
	th {
		font-size: 14px;
		/* line-height: 14px; */
		padding: 0.5rem 0 !important;
	}
	.namehistory-th {
		padding: 0.5rem 0 !important;
	}

	.cancel-bid {
		font-size: 12px;
	}

	.cancel-offer {
		height: 56px;
	}
}

.bid-header {
	font-size: 24px !important;
	margin-top: 16px;
	font-weight: 500 !important;
}

#notification-img {
	width: 52px !important;
	height: 52px !important;
	border-radius: 50% !important;
	object-fit: cover !important;
	margin-left: 10px !important;
	margin-bottom: 0 !important;
	padding: 8px !important;
}
