.lets-browse-search {
  padding-top: 50px;
}
* {
  scrollbar-width: thin;
  scrollbar-color: darkslategrey black;
}

::-webkit-scrollbar {
  width: 0.6em;
  border-radius: 5px;
  background-color: black;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}
::-webkit-scrollbar-thumb {
  background-color: #fc4ba3;
  outline: 0px solid slategrey;
  border-radius: 5px;
}
