.messaging-container {
	margin-top: -54px;
	display: flex;
}

.messaging-container.align-center {
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	width: 100%;
}

.active-conversation {
	padding: 20px 12px 20px 12px;
	background: #fff;
	min-height: 579px;
	height: 600px;
	overflow-y: scroll;
	border-top-left-radius: 16px;
	border-bottom-left-radius: 16px;
	max-width: 230px;
	border: 2px solid #329993;
}

.active-conversation.rounded-full {
	border-radius: 16px;
}

.center-btn {
	display: flex;
	align-items: center;
	justify-content: center;
}

.start-chat {
	display: flex;
	padding: 12px 20px;
	justify-content: center;
	align-items: center;
	gap: 12px;
	border-radius: 52px;
	background: #fc4ba3;
	color: #fff;
	width: 298px;
	border: none;
	outline: none;
	color: #fff;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.recent-conversations {
	display: flex;
	flex-direction: column;
	gap: 12px;
	margin-top: 24px;
}

.selected-convo {
	border-left: 6px solid #329993;
	background: rgba(63, 236, 198, 0.2);
	margin-left: -12px;
}

.unread-convo {
	margin-left: -12px;
	border-left: 6px solid #0269ec;
}
.recent-convo-name {
	color: #171c1b;
	font-size: 0.875rem;
	font-weight: 600;
}

.recent-convo-content {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.convo-image-name {
	display: flex;
	gap: 10px;
	align-items: center;
}

.recent-convo-image {
	width: 32px;
	height: 32px;
	border-radius: 16px;
}

.chat-container {
	width: 484px;
	height: 600px;
	border: 2px solid #329993;
	overflow: hidden;
	position: relative;
	/* background: #329993; */
	/* background: #fff; */
	background: rgba(63, 236, 198, 0.5);
	border-top-right-radius: 16px;
	border-bottom-right-radius: 16px;
	border-left: 0;
}

.chat-history {
	width: 100%;
	height: calc(100% - 100px);
	overflow-y: auto;
	/* padding: 0 12px; */
	/* position: relative; */
}

.chat-history-header {
	border-bottom: 2px solid #329993;
	background-color: #fff;
	position: sticky;
	top: 0;
	left: 0;
	padding: 8px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.flex.item-center {
	display: flex;
	align-items: center;
}

.item-center span {
	font-weight: 600;
	font-size: 0.875rem;
}

.chat-input {
	width: 100%;
	height: 100px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.chat-input textarea {
	width: 368px;
	height: 80px;
	flex-shrink: 0;
	border-radius: 20px;
	border: 1px solid #e3e8e7;
	background: #fff;
	color: #329993;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px;
	padding: 20px;
}

textarea::placeholder {
	color: #329993;
}

/* .chat-input button {
	background: none;
	outline: none;
	border: none;
	color: #fff;
	background-color: #fc4ba3;
	padding: 8px;
	border: 2px solid #fc4ba3;
	border-radius: 21px;
} */

.send-messaging {
	border: none;
	outline: none;
	background: none;
}

.spinner {
	color: #fc4ba3;
	margin-top: 220px;
	margin-left: 210px;
}

.messageImg {
	width: 52px;
	height: 52px;
	border-radius: 50%;
	object-fit: cover;
	margin-left: 10px;
	margin-bottom: 0;
	padding: 8px;
}

/* .unread-conversation {
	border: 1px dashed red;
} */

.unread-icon {
	width: 8px;
	height: 8px;
	background-color: red;
	border-radius: 50%;
}

.last-message {
	margin: 0;
	overflow: hidden;
	color: #465352;
	text-overflow: ellipsis;
	font-size: 14px;
	line-height: 18px; /* 128.571% */
	max-height: 58px;
}

.last-message-timestamp {
	overflow: hidden;
	color: #758a89;
	text-overflow: ellipsis;
	font-size: 12px;
	font-weight: 500;
	line-height: normal;
	margin: 0;
	min-width: 32px;
}

.recent-convo {
	cursor: pointer;
	/* padding-bottom: 12px;
	padding-top: 12px; */
	padding: 12px;
	border-bottom: 1px solid #e3e8e7;
}

.recent-convo:hover {
	background: rgba(63, 236, 198, 0.2);
	margin-left: -12px;
}

.message-box {
	width: 100%;
}

.select-messaging-recipient {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding-top: 32px;
}
.active-conversation.show-mobile-ac {
	display: none;
}
.menu-icon {
	display: none;
}

.hide-mobile-ac {
	display: none;
}

@media screen and (max-width: 1387px) {
	.start-chat {
		font-size: 0.875rem;
		padding: 12px;
		gap: 6px;
	}

	.active-conversation {
		display: none;
	}

	.active-conversation.show-mobile-ac {
		display: block;
		position: absolute;
		z-index: 999;
		transition: opacity 0.5s ease, max-height 0.5s ease; /* You can adjust the duration and timing function as needed */
		opacity: 1;

		/* border-top-left-radius: 0;
		border-bottom-left-radius: 0; */
	}

	.active-conversation.hide-mobile-ac {
		display: none;
		opacity: 0;
	}

	.chat-container {
		border-top-left-radius: 16px;
		border-bottom-left-radius: 16px;
	}

	.menu-icon {
		margin-right: 10px;
		display: block;
	}
}

@media screen and (max-width: 991px) {
}
