.custom-tooltip-container {
	position: relative;
	display: inline-block;
}

.custom-tooltip {
	position: absolute;
	color: #fff;
	padding: 5px;
	border-radius: 4px;
	top: 100%;
	left: 70%;
	transform: translateX(-50%);
	z-index: 1;
	transition: opacity 0.3s, visibility 0.3s;
}

.custom-tooltip-content {
	display: block;
	border: 1px solid #badbcc;
	width: max-content;
	color: #0f5132;
	background-color: #d1e7dd;
	padding: 16px;
	border-radius: 4px;
	font-size: 12px;
	margin-right: 32px;
	max-width: 300px;
}
