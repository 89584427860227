.tab-list-custom {
	display: flex;
	align-items: center;
	justify-content: space-between;
	list-style: none;
	margin-bottom: 64px;
}

.tab-custom {
	cursor: pointer;
	text-transform: uppercase;
	padding: 8px 16px;
}

.tab-custom.services-tabs {
	color: #0269ec;
	/* padding: 8px 16px; */
	font-size: 20px;
	padding-bottom: 8px;
	padding-top: 0;
}

.tab-list-custom.services-tabs {
	padding-left: 0;
}

.tab-custom.services-tabs:first-child {
	padding-left: 0;
}

.tab-custom.services-tabs:last-child {
	padding-right: 0;
}

.tab-custom.services-tabs:hover {
	cursor: pointer;
	color: #77e5c4;
	background: none;
	border: none;
	border-radius: 0px;
	/* padding: 8px 0; */
	padding-bottom: 8px;
	padding-top: 0;
}

.active-tab-custom {
	border: 1px solid #3fecc6;
	padding: 8px 16px;
	border-radius: 24px;
	background-color: rgba(63, 236, 198, 0.1);
}

.tab-custom.services-tabs.active-tab-custom {
	cursor: pointer;
	color: #77e5c4;
	background: none;
	border: none;
	border-bottom: 2.67px solid #77e5c4;
	border-radius: 0px;
	padding-right: 0;
	padding-left: 0;
}

.tab-custom.disabled-tab {
	opacity: 0.5; /* Reduce opacity to make it appear faded */
	pointer-events: none; /* Disable pointer events to prevent interaction */
	/* Optionally, you can add styles to change cursor on hover */
	cursor: not-allowed;
}

@media screen and (max-width: 768px) {
	.tab-list-custom.services-tabs {
		padding: 0;
		justify-content: space-between;
	}
}

@media screen and (max-width: 522px) {
	.tab-list-custom.services-tabs {
		padding: 0;
		justify-content: space-between;
		font-size: 0.75rem;
	}
}
