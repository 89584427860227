.copyright-section p {
  color: #fff;
  font-weight: bold;
  font-size: 24px;
  margin-top: -24px;
}

.footer-menu {
  background-image: linear-gradient(90deg, #021d58, #013ebb);
}

.footer-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer-menu ul li {
  display: inline-block;
  margin: 0 35px;
}

.footer-menu ul li a {
  text-decoration: none;
  color: #3fecc6;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
}

.footer-menu {
  padding-top: 30px;
  padding-bottom: 50px;
}

img.copyright-image {
  width: 100%;
}

.copyright-section p {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .copyright-section p {
    margin-top: 25px;
  }
  .footer-menu ul li a {
    font-size: 15px;
  }

  .footer-menu ul li {
    margin: 8px 18px;
  }

  .footer-menu {
    padding-bottom: 35px;
  }
}
