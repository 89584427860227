section.become-provider-section {
  margin-top: 150px;
}

section.cta-section, section.cta-section-2 {
  padding: 100px 0;
  background-image: linear-gradient(45deg, #023ebb, #021d58);
}

.cta-text a {
  font-size: 35px;
  color: #3fecc6;
  text-decoration: none;
  font-weight: bold;
  border: 6px solid #ff7bc3;
  display: inline-block;
  padding: 15px 90px;
  border-radius: 100px;
}

@media screen and (max-width: 1680px) {
  section.cta-section, section.cta-section-2 {
    padding: 50px 0;
  }
}

@media screen and (max-width: 1440px) {
  .cta-text a {
    font-size: 30px;
  }
}

@media screen and (max-width: 991px) {
  section.cta-section-2 {
    margin-top: 700px;
  }
}

@media screen and (max-width: 767px) {
  .cta-text a {
    font-size: 17px;
  }
  section.cta-section-2 {
    margin-top: 0px;
  }
}
