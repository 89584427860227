.featured-section .container {
  max-width: 1640px;
}

.featured-content img {
  margin-left: -25px;
  margin-top: -30px;
}

.featured-content p {
  margin-bottom: 50px;
}

.featured-content img {
  margin-left: -25px;
}

.button-wrapper {
  text-align: center;
}

.inverted-item .nftr-text-box {
  max-width: 500px;
}

.featured-content {
  margin-left: 50px;
}

.inverted-item .featured-content {
  margin-left: 0px;
}

.featured-section {
  margin-top: 150px;
}

@media screen and (max-width: 1680px) {
  .featured-section .container {
    max-width: 1320px;
  }
}

@media screen and (max-width: 767px) {
  .featured-content {
    margin-left: 0;
    margin-top: 10px;
    text-align: center;
  }
}

@media screen and (max-width: 585px) {
  .featured-section {
    margin-top: 50px;
  }
}
