.hand-thumbnail {
  position: absolute;
  right: 0;
  top: 0;
}

.nft-section .nftr-text-box {
  margin-left: 25%;
  margin-right: -35%;
  margin-top: 250px;
}

.nft-section .nftr-text-box p {
  margin-left: 110px;
  margin-bottom: 0;
  margin-top: 0px;
}

.nft-section {
  margin-top: -200px;
}

@media screen and (max-width: 1921px) {
  .nft-section .nftr-text-box {
    margin-right: -15%;
  }
}

@media screen and (max-width: 1680px) {
  .nft-section .nftr-text-box {
    margin-right: 0;
    margin-top: 150px;
    position: relative;
    z-index: 2;
  }
}

@media screen and (max-width: 1199px) {
  .hand-thumbnail {
    opacity: 0.3;
  }
}

@media screen and (max-width: 991px) {
  .nft-section .nftr-text-box {
    margin-top: 0;
  }
  .nft-section {
    margin-top: 0;
  }
  .nft-section .nftr-text-box {
    margin-left: 0;
    text-align: center;
  }

  .nft-section .nftr-text-box p {
    margin-left: 0;
    margin-top: 0px;
  }

  .nft-section .nftr-text-box {
    margin-top: 40px;
  }
}

@media screen and (max-width: 585px) {
  .nft-section .nftr-text-box {
    text-align: left;
  }
}
