header {
  position: absolute;
  width: 100%;
  z-index: 999;
}

header .nav-link {
  font-weight: bold;
  font-size: 19px;
  text-transform: uppercase;
  padding: 0 20px !important;
}

header .container {
  max-width: 1685px;
}

@media screen and (max-width: 1680px) {
  header .container {
    max-width: 85%;
  }
}
