.bid-button-group {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 32px;
}

.transfer-button {
  padding: 10px 40px;
  background: none;
  outline: none;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: 300;
  text-transform: uppercase;
  margin-top: 36px;
  color: #fff;
  background-color: none;
  outline: none;
  border: 2px solid #fc4ba3;
  width: 160px;
  height: 52px;
}

.transfer-button:hover {
  background-color: #fc4ba3;
  color: #fff;
}

.bid-button {
  padding: 10px 40px;
  background: none;
  outline: none;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: 300;
  text-transform: uppercase;
}

.bid-button:first-child {
  color: #77e5c4;
  border: 2px solid #77e5c4;
}

.bid-button:first-child:hover {
  color: #fff;
  border: 2px solid #77e5c4;
  background: #77e5c4;
  box-shadow: 0 0 10px #3fecc6;
}

.bid-button:nth-child(2) {
  border: 2px solid #2d67e1;
  color: #2d67e1;
}

.bid-button:nth-child(2):hover {
  border: 2px solid #2d67e1;
  color: #fff;
  background: #2d67e1;
  box-shadow: 0 0 10px #2d67e1;
}

.cancel-bid {
  padding: 2px 20px;
  background: none;
  outline: none;
  border-radius: 50px;
  font-size: 1rem;
  border: 1px solid #fc4ba3 !important;
  color: #fc4ba3;
  height: 36px;
  font-weight: 300;
}

.cancel-bid:hover {
  color: #fff;
  background: #fc4ba3;
  box-shadow: 0 0 10px #fc4ba3;
}

.accordion-collapse {
  background-color: transparent !important;
}

@media only screen and (max-width: 768px) {
  .cancel-bid {
    font-size: 12px;
    padding: 0 8px;
  }

  .bid-header {
    font-size: 22px !important;
    margin-top: 16px;
    font-weight: 500 !important;
  }
}
