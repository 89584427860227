.footer {
    position: fixed;
    float: right;
    width: 25%;
    bottom: 0;
    z-index: 5000;
    right: 0;
}

  
  @media screen and (max-width: 1440px) {
    .footer {
      width: 35%;
    }
  }
  
  @media screen and (max-width: 767px) {
    .footer {
      width: 50%;
    }
  }