.NameHistory {
  margin: 0 auto;
  width: auto;
}

.NameHistory h2 {
  color: #3fecc6;
  border-bottom: 1px solid #3fecc6;
  text-align: center;
  padding-bottom: 12px;
  font-weight: 400;
  margin-bottom: 12px;
  width: 450px;
  margin-right: auto;
  margin-left: auto;
}

.NameHistory table {
  margin: auto;
}

thead {
  border-bottom: 1px solid #3fecc6;
  text-align: center;
}

.NameHistory th {
  font-size: 24px;
  font-weight: 400;
  color: #3fecc6;
  text-align: center;
}

tr {
  text-align: center;
}

td,
th {
  /* font-size: 24px; */
  font-size: 18px;
  line-height: 21.78px;
  padding-bottom: 16px;
  width: auto;
  padding-top: 16px;
  vertical-align: baseline;
}

td:first-child {
  width: 100px;
  /* padding-right: 42px; */
}

td:nth-child(2) {
  /* padding-right: 42px; */
}

td:nth-child(2) .pill {
  border: 1px solid #3fecc6;
  padding: 2px 16px;
  text-align: center;
  border-radius: 14px;
  background: rgba(63, 236, 198, 0.3);
  color: white;
  text-decoration: none;
}

td:nth-child(3) a {
  text-align: right;
  color: #fc4ba3;
  text-decoration: none !important;
}

/* .namehistory-td:last-child {
  padding-top: 0px;
  padding-bottom: 0px;
} */

@media only screen and (max-width: 768px) {
  .NameHistory h2 {
    width: auto;
    margin-top: 24px;
  }

  td,
  th {
    font-size: 14px;
  }

  .NameHistory table {
    width: 100%;
  }
}
