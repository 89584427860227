@media screen and (max-width: 1680px) {
	section.lets-browse-search .shapes img {
		max-width: 25%;
	}
	.golden-ticket-shapes img {
		max-width: 20%;
	}
}
@media screen and (max-width: 1440px) {
	.invite-shapes img {
		max-width: 20%;
	}
	.robot-details-shape img {
		max-width: 30%;
	}
	.robot-details-content h3 {
		font-size: 30px;
	}

	.robot-details-content h3 span {
		font-size: 30px;
	}

	p.robot-id {
		font-size: 25px;
	}

	.robot-details-content .navigation {
		font-size: 25px;
	}

	.earn-content .earn-buttons a {
		font-size: 20px;
	}	
}
@media screen and (max-width: 1199px) {
	.lets-browse-search-text h1 {
		font-size: 70px;
	}

	.lets-browse-search-text input[type='search'] {
		font-size: 50px;
	}
	.invite-shapes {
		display: none;
	}
	.invite-content button {
		width: 100%;
		padding: 10px 0;
	}
	.robot-details-text h4 {
		font-size: 25px;
	}

	.robot-details-data p {
		font-size: 20px !important;
	}

	.robot-details-data p:first-child {
		font-size: 20px !important;
	}
	.earn-shapes {
		display: none;
	}
	.earn-component p.text-green,
	.earn-component p.text-pink {
		font-size: 25px;
	}

	.earn-component p:last-child {
		font-size: 20px;
	}
	.earn-card.first-card {
		padding-top: 80px;
	}
}

@media screen and (max-width: 991px) {

	.header-one .navbar-nav a {
		margin-bottom: 15px;
	}

	  .header-two .navbar-nav a:after {
		margin-bottom: 2px;
	  }		  
	.robot-slide-item span {
		font-size: 15px;
	}
	.header-two .navbar-nav a {
		margin-bottom: 15px;
	}

	.header-two .navbar-nav {
		margin-bottom: 15px;
	}
	.invite-content h1 {
		font-size: 40px;
	}
	.golden-ticket-content a.bordered-button {
		font-size: 16px;
	}
	.golden-ticket-content a.bordered-button:before {
		display: none;
	}
	.golden-ticket-ratio-content h5 {
		bottom: 75%;
	}
	.robot-details-thumbnail {
		margin-bottom: 30px;
	}
	.robot-details-text {
		margin-left: 0;
	}
	.earn-content h1 {
		font-size: 35px;
	}

	section.earn-section {
		padding-top: 50px;
	}
	.earn-content p {
		font-size: 30px;
	}
	.earn-card.first-card:after {
		display: none;
	}
	.earn-card.first-card {
		margin-bottom: 60px;
	}
	.golden-ticket-shapes {
		display: none;
	}
	.wallet-container {
		margin-top: 0;
	}
	.wallet {
		text-align:center;
	}
	.header-two .navbar {
		margin-top:80px;
		max-height:2000px;
	}

	.home-button {
		margin-top:0px;
		margin-bottom:130px;
	}

	.earn-yield-tab-section ul li a {
		font-size: 15px;
	}	
}

@media screen and (max-width: 767px) {
	.robot-filter span {
		font-size: 20px;
	}
	.robot-filter span {
		font-size: 20px;
	}

	.lets-browse-search-text input[type='search'] {
		width: 100%;
		padding: 10px 30px;
		font-size: 35px;
		border: 4px solid #fc4ba3;
	}

	section.robot-lists {
		margin-top: 40px;
	}
	.pagination-robots a,
	.pagination-robots span {
		font-size: 15px;
		width: 40px;
		height: 40px;
		line-height: 26px;
	}
	.invite-content h1 {
		font-size: 40px;
	}

	section.invite-section {
		padding-top: 70px;
	}

	.invite-content h1 {
		font-size: 30px;
	}

	.invite-content p {
		font-size: 22px;
	}

	.invite-content input[type='password'] {
		font-size: 90px;
		padding: 0;
	}

	.invite-content button {
		font-size: 30px;
	}
	.golden-ticket-content p {
		font-size: 16px;
	}

	.golden-ticket-ratio-content p {
		font-size: 16px;
	}

	.golden-ticket-ratio a.bordered-button {
		font-size: 22px;
		padding: 15px 0;
	}
	.golden-ticket-box p {
		font-size: 16px;
	}
	.golden-ticket-box h4 {
		font-size: 20px;
	}
	.golden-ticket-box h4 {
		font-size: 20px;
	}

	.golden-ticket-ratio-content img {
		width: 100%;
	}

	section.golden-ticket-section {
		padding-top: 60px;
	}
	.golden-ticket-box {
		margin-bottom: 30px;
	}
	section.robot-details {
		padding-top: 50px;
	}
	.earn-content .bordered-button {
		width: 100%;
		font-size: 24px;
	}
	.earn-content .bordered-button-2 {
		width: 100%;
		font-size: 24px;
	}

	.earn-card {
		margin: 0;
	}
	.earn-card {
		padding-left: 15px;
		padding-right: 0;
	}

	.header-two .navbar {
		margin-top:80px;
		max-height:2000px;
	}

	.home-button {
		margin-top:0px;
		margin-bottom:130px;
	}

	.earn-content .earn-buttons a {
		margin-bottom: 15px;
		font-size: 15px;
	  }
	
	  .earn-buttons {
		margin-top: 15px !important;
	  }
	
	  .earn-content {
		margin-bottom: 30px !important;
	  }
	  .earn-yield-tab-section ul li {
		margin: 0;
	  }
	
	  .earn-yield-tab-section ul {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		text-align: center;
		gap: 20px;
		margin-bottom: 20px;
	  }
	
	  .earn-yield-tab-section ul {
		border: none;
	  }
	  .earn-yield-tab-section .tab-content-wrapper {
		padding: 20px;
	  }
	  .statistics-row {
		flex-direction: column;
	  }
	
	  .accordion-body {
		padding: 16px;
	  }
	
	  .statistics-bottom-inner {
		flex-direction: column;
		text-align: left;
		justify-content: start !important;
		align-items: start !important;
	  }
	
	  .statistics-bottom-inner .switch {
		margin-top: 20px;
	  }
	
	  .accordion-header-left h4 span {
		font-size: 20px;
	  }
	
	  .textbox-space-between {
		flex-direction: column;
		align-items: start;
	  }
	  .form-group-input input[type='submit'] {
		font-size: 14px;
		padding: 22px;
	  }
	  .form-group-input input[type='submit'] {
		width: 35%;
	  }
	  .blue-box {
		padding: 15px;
		flex-direction: column;
		align-items: start !important;
	  }
	
	  .blue-box-right {
		margin-top: 20px;
	  }
	
	  .blue-box-padding.blue-box.d-flex.justify-content-between.align-items-center.mt-30.mb-30.border-top.border-bottom {
		margin-top: 15px;
		margin-bottom: 15px;
	  }
	  .statistics-row.accordion-naming-bulk-name {
		align-items: start;
	  }	

}

@media screen and (max-width: 585px) {
	.lets-browse-search-text h1 {
		font-size: 35px;
		margin-bottom: 10px;
	}

	.lets-browse-search-text p {
		font-size: 20px;
	}

	.robot-text h4 {
		font-size: 16px;
	}

	.robot-text p {
		font-size: 16px;
	}
	.robot-details-text a.bordered-button {
		font-size: 30px;
	}

	.account-bar {
		text-align:center;
	}

	.header-two .navbar {
		margin-top:130px;
		max-height:2000px;
	}
	.wallet {
		text-align:center;
	}
	.home-button {
		margin-top:0px;
		margin-bottom:130px;
	}

}
