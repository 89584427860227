.earn-rnm-section .nftr-text-box .pink-bar {
  margin-top: -30px;
}

.service-box h3 {
  font-size: 36px;
  color: #3fecc6;
  font-weight: 300;
}

.service-box p {
  color: #fff;
  font-size: 18px;
  max-width: 250px;
  margin: 0 auto;
}

.service-box img {
  margin-bottom: 25px;
}

.earn-rnm-section .nftr-text-box {
  margin-bottom: 100px;
}

.service-box p {
  line-height: 1.2;
}

@media screen and (max-width: 1199px) {
  section.earn-rnm-section {
    margin-top: 150px;
  }
}

@media screen and (max-width: 767px) {
  .earn-box-second.col-md-4 {
    margin-top: 0 !important;
  }

  .service-box {
    margin-bottom: 30px;
  }

  .earn-rnm-section .nftr-text-box {
    margin-bottom: 25px;
  }
}
