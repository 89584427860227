.accordion-button {
	/* color: #fc4ba3; */
	font-family: "Futura LT";
	font-size: 22.75px;
	color: #2d67e4;
	align-items: center;
}

.accordion-button::after {
	margin-bottom: 0 !important;
}
.naming {
	margin-bottom: 8px;
}

.naming span {
	width: 16px;
	height: 16px;
	background-color: #2d67e4;
	border-radius: 50%;
	margin-right: 20px;
}

.twitter,
.email {
	display: flex;
	gap: 16px;
	cursor: pointer;
}

.naming .accordion-button {
	border: 2px solid #2d67e4;
	/* width: 462px; */
	height: 44px;
	border-radius: 22px;
	border-top-left-radius: 22px !important;
	border-top-right-radius: 22px !important;
	border-bottom-left-radius: 22px !important;
	border-bottom-right-radius: 22px !important;
	font-size: 22.75px;
	padding: 12px;
}

/* .accordion-button:focus {
  color: #fc4ba3;
  font-family: "Futura LT";
} */

.action-buttons {
	margin-top: 16px;
}
.history.action-buttons button,
.offer-btn {
	font-weight: 300;
	border: none;
	color: #3fecc6;
	width: 190px;
	height: 50px;
	border-radius: 12px;
	background: none;
	border-radius: 30px;
	font-size: 21px;
	margin-right: 8px;
}

.offer,
.list {
	padding: 40px 20px;
	font-size: 1rem;
}

.modal-dialog {
	max-width: 700px;
}

.modal-content {
	border-radius: 40px;
	width: 700px;
	padding: 16px 24px;
	background-color: #3fecc6;
	/* min-height: 386px; */
}

.modal-body p {
	color: #030a34;
	text-align: center;
	font-size: 16px;
}

/* .modal-header {
  color: #030a34;
  text-align: center;
  font-size: 24px;
  font-weight: 800;
  border-bottom: 1px solid #0000004d;
} */

.modal-title.h4 {
	display: flex;
	text-align: center;
	color: #04111d;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	font-weight: 600;
	font-size: 24px;
	color: #030a34;
}

.modal-header .btn-close {
	position: absolute;
	right: 20px;
	top: 36px;
}

.modal-footer {
	justify-content: center;
	flex-direction: column;
	border-top: none;
}

.offer-btn {
	font-size: 24px;
	/* width: 1; */
	background-color: #022061;
	color: white;
}

.offer-btn:hover {
	/* border: 2px solid #fc4ba3; */
	/* color: #3fecc6; */
	background-color: #fc4ba3;
}

.namehistory-table {
	width: 100%;
}

.react-dropdown-select {
	border: 2px solid #fc4ba3 !important;
	padding: 16px !important;
	border-radius: 16px !important;
}

.react-dropdown-select-option {
	padding: 8px !important;
	margin-left: 24px !important;
	font-size: 20px !important;
}

.react-dropdown-select-input::placeholder {
	color: #fff !important;
	font-size: 20px !important;
}

.react-dropdown-select-dropdown {
	padding: 20px !important;
}

.react-dropdown-select-item {
	font-size: 20px !important;
	padding: 8px !important;
}

.namehistory-name-td {
	text-align: center !important;
}

.services-tabs {
	margin-top: -16px;
	justify-content: space-around;
	border: none;
}

.services-tabs.disabled-tab {
	opacity: 0.5; /* Reduce opacity to make it appear faded */
	pointer-events: none; /* Disable pointer events to prevent interaction */
	cursor: not-allowed;
}

.nav-tabs .nav-item {
	margin-right: 10px;
}
.nav-tabs {
	border-bottom: none !important;
}

.nav-link,
.nav-link:hover {
	font-size: 20px;
	color: #2d67e1;
	border: none;
	/* padding: 10px 56px; */
	padding: 0 16px 10px 16px;
	margin-top: -10px;
	/* border: 2px solid #2d67e1; */
}

/* .nav-tabs .nav-link {
  border: 2.67px solid #2d67e1;
  border-radius: 50px;
} */

/* active tabs */

.nav-tabs .nav-link {
	cursor: pointer;
}

.nav-tabs .nav-link.active {
	cursor: pointer;
	color: #77e5c4;
	background: none;
	border: none;
	border-bottom: 2.67px solid #77e5c4;
	/* border-radius: 50px; */
}

.nav-tabs .nav-link:hover {
	border: none !important;
}

.nav-tabs .nav-link:focus {
	border: none !important;
}

.nav-tabs .nav-link:focus-visible {
	border: none !important;
}

/* .nav-tabs .nav-link.disabled {
  color: #77e5c4;
  background: none;
  border: none;
  border: 2.67px solid #2d67e1;
  border-radius: 50px;
} */

.services-tabs {
	margin-bottom: 54px;
}

.banking {
	margin-bottom: 26px;
}

.banking .nav-link {
	font-size: 17px;
	padding: 8px 50px;
}

/* .select-token-wrapper {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  width: 484px;
  height: 48px;
  background-color: #022061;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: #fc4ba3;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  cursor: auto;
} */

.select-token-wrapper {
	box-sizing: border-box;
	margin: 0px;
	min-width: 0px;
	display: flex;
	width: 484px;
	height: 48px;
	/* background-color: #022061; */
	background: none;
	padding-left: 12px;
	padding-right: 12px;
	border-radius: 48px;
	border-style: solid;
	border-width: 2px;
	border-color: #fc4ba3;
	-webkit-box-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	align-items: center;
	cursor: auto;
}

.select-token-container {
	box-sizing: border-box;
	margin: 0px;
	min-width: 0px;
	flex: 1 1 0%;
}

.dropdown-icon {
	box-sizing: border-box;
	margin: 0px;
	min-width: 0px;
	font-size: 16px;
	fill: currentcolor;
	transform: rotate(0deg);
	color: rgb(112, 122, 138);
	width: 1em;
	height: 1em;
}

.select-token-content {
	box-sizing: border-box;
	margin: 0px;
	min-width: 0px;
	display: flex;
	flex-wrap: wrap;
	flex: 1 1 0%;
	-webkit-box-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	align-items: center;
	cursor: pointer;
}

.select-token-details {
	box-sizing: border-box;
	margin: 0px 4px 0px 0px;
	min-width: 0px;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	width: auto;
	flex-shrink: 0;
}

/* .select-token-text {
  margin: 0;
  color: #fc4ba3;
  font-weight: 600;
  margin-left: 8px;
} */

.select-token-text {
	margin: 0;
	color: #030a34;
	/* font-weight: 600; */
	margin-left: 8px;
	font-size: 22px;
}

/* .dropdown-container {
  border: 1px solid #fc4ba3;
  width: 484px;
  margin-top: 16px;
  padding: 24px;
  background-color: #022061;
  border-radius: 4px;
} */
.robot-details-twitter {
	display: flex;
	align-items: center;
}

.robot-details-twitter {
	font-size: 16px;
}

.dropdown-container {
	border: 1px solid #fc4ba3;
	width: 484px;
	margin-top: 16px;
	padding: 24px;
	background: none;
	border-radius: 16px;
}

/* .select-token-search {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  color: #fc4ba3;
  border: 1px solid #fc4ba3;
  background: none;
  margin-bottom: 24px;
  border-radius: 4px;
} */

.select-token-search {
	width: 100%;
	font-size: 16px;
	color: #030a34;
	border: 2px solid #fc4ba3;
	background: none;
	border-radius: 24px;
	height: 48px;
	padding-left: 12px;
}

.select-token-search::placeholder {
	color: #030a34;
}

.select-token-search::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #030a34;
	opacity: 1; /* Firefox */
}

.select-token-search::-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #030a34;
}

.select-token-search::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #030a34;
}

input:focus {
	outline: none;
}

input::placeholder {
	vertical-align: middle;
	height: 40px;
}

.token-list {
	padding: 0;
	list-style: none;
}

.select-token-image {
	width: 32px;
	height: 32px;
	margin-right: 32px;
}

.token-list-item {
	padding: 16px;
	/* color: #fc4ba3; */
	color: #030a34;
	cursor: pointer;
	font-size: 20px;
	font-weight: 500;
}

.token-list-item:last-child {
	margin-bottom: 60px;
}

.select-token-wrapper.deposit {
	margin-top: 40px;
	/* color: #fc4ba3; */
	color: #030a34;
}

.tab-content > .active {
	margin-top: 40px;
}

.deposit-balance {
	display: flex;
	justify-content: flex-end;
	width: 484px;
	color: #030a34;
}

.deposit-max {
	outline: none;
	background: none;
	border: none;
	/* color: #fc4ba3; */
	color: #2d67e4;
	text-decoration: underline;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
	appearance: textfield;
}

.deposit-button {
	width: 484px;
	margin-top: 32px;
	height: 48px;
	background-color: #022061;
	border: 2.65px solid #fc4ba3;
	color: #77e5c4;
	border-radius: 24px;
	font-size: 22px;
	font-weight: 300;
}

.deposit-button:hover {
	background-color: #fc4ba3;
}

.disabled {
	opacity: 0.6;
	cursor: not-allowed;
}

.find-nft {
	display: flex;
	/* justify-content: space-between; */
	margin-top: 32px;
	align-items: flex-end;
}

.transfer-search-fields.contractAddress {
	margin-right: 8px;
}

.transfer-search-fields.contractAddress {
	width: 54%;
}

.transfer-search-fields.tokenId {
	width: 11%;
}

.transfer-search {
	padding: 12px;
	font-size: 16px;
	border: 1px solid #fc4ba3;
	background: none;
	margin-bottom: 24px;
	border-radius: 4px;
	color: #3fecc6;
	background-color: #022061;
	height: 48px;
}

.transfer-searchfields {
	display: flex;
	flex-direction: column;
}

.validate-nft {
	display: flex;
	justify-content: flex-end;
	width: 470px;
}

.validate-nft-image {
	width: 25px;
	height: auto;
	border-radius: 12.25px;
}

.search-label {
	font-size: 16px;
	margin-bottom: 8px;
	color: #fc4ba3;
}

#chat4 .form-control {
	border-color: transparent;
}

#chat4 .form-control:focus {
	border-color: transparent;
	box-shadow: inset 0px 0px 0px 1px transparent;
}

.divider:after,
.divider:before {
	content: "";
	flex: 1;
	height: 1px;
	background: #eee;
}

#chat1 .form-outline ~ .form-notch div {
	pointer-events: none;
	border: 1px solid;
	border-color: #eee;
	box-sizing: border-box;
	background: transparent;
}

#chat1 .form-outline ~ .form-notch .form-notch-leading {
	left: 0;
	top: 0;
	height: 100%;
	border-right: none;
	border-radius: 0.65rem 0 0 0.65rem;
}

#chat1 .form-outline ~ .form-notch .form-notch-middle {
	flex: 0 0 auto;
	max-width: calc(100% - 1rem);
	height: 100%;
	border-right: none;
	border-left: none;
}

#chat1 .form-outline ~ .form-notch .form-notch-trailing {
	flex-grow: 1;
	height: 100%;
	border-left: none;
	border-radius: 0 0.65rem 0.65rem 0;
}

#chat1 .form-outline:focus ~ .form-notch .form-notch-leading {
	border-top: 0.125rem solid #39c0ed;
	border-bottom: 0.125rem solid #39c0ed;
	border-left: 0.125rem solid #39c0ed;
}

#chat1 .form-outline:focus ~ .form-notch .form-notch-leading,
#chat1 .form-outline.active ~ .form-notch .form-notch-leading {
	border-right: none;
	transition: all 0.2s linear;
}

#chat1 .form-outline:focus ~ .form-notch .form-notch-middle {
	border-bottom: 0.125rem solid;
	border-color: #39c0ed;
}

#chat1 .form-outline:focus ~ .form-notch .form-notch-middle,
#chat1 .form-outline.active ~ .form-notch .form-notch-middle {
	border-top: none;
	border-right: none;
	border-left: none;
	transition: all 0.2s linear;
}

#chat1 .form-outline .form-control:focus ~ .form-notch .form-notch-trailing {
	border-top: 0.125rem solid #39c0ed;
	border-bottom: 0.125rem solid #39c0ed;
	border-right: 0.125rem solid #39c0ed;
}

#chat1 .form-outline .form-control:focus ~ .form-notch .form-notch-trailing,
#chat1 .form-outline .form-control.active ~ .form-notch .form-notch-trailing {
	border-left: none;
	transition: all 0.2s linear;
}

#chat1 .form-outline .form-control:focus ~ .form-label {
	color: #39c0ed;
}

#chat1 .form-outline .form-control ~ .form-label {
	color: #bfbfbf;
}

#chat-container {
	position: fixed;
	bottom: 0;
	right: 0;
}

.bg-pink {
	background-color: #fc4ba3;
}

.conversation {
	display: flex;
	align-items: center;
	padding: 10px;
	cursor: pointer;
	margin-top: 20px;
}

.conversation:hover {
	background-color: rgb(245, 243, 243);
}

.conversationImg {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	object-fit: cover;
	margin-right: 20px;
}

.conversationName {
	font-weight: 500;
}

.robot-details-twitter .email-edit {
	display: flex;
	align-items: center;
	gap: 8px;
}
.email-edit p {
	margin-bottom: 0;
	color: #fff;
}

@media screen and (max-width: 768px) {
	.conversationName {
		display: none;
	}
}

.message {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
}

.messageTop {
	display: flex;
	align-items: center;
}

.recent-convo-name {
	margin-bottom: 0;
}

.messageText {
	min-width: 200px;
	max-width: 340px;
	padding: 20px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	flex-shrink: 0;
	border-radius: 20px 20px 20px 0px;
	background: #fff;
	color: #171c1b;
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
}

.message-blur {
	filter: blur(8px);
}

.messageBottom {
	font-size: 12px;
	margin-top: 10px;
	color: #fff;
	margin-left: 8px;
	margin-right: 8px;
}

.message.own {
	align-items: flex-end;
}

.message.own .messageText {
	background: #329993;
	color: #fff;
	text-align: right;
	border-radius: 20px 20px 0px 20px;
}

.messenger {
	height: calc(100vh - 70px);
	display: flex;
}

.chatMenu {
	flex: 3.5;
}

.chatMenuInput {
	width: 90%;
	padding: 10px 0;
	border: none;
	border-bottom: 1px solid gray;
}

.chatBox {
	flex: 5.5;
}

.chatBoxWrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
}

.chatBoxTop {
	height: 100%;
	overflow-y: scroll;
	padding-right: 10px;
}

.chatBoxBottom {
	margin-top: 5px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.chatMessageInput {
	width: 80%;
	height: 90px;
	padding: 10px;
}

.chatSubmitButton {
	width: 70px;
	height: 40px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	background-color: teal;
	color: white;
}

.chatOnline {
	flex: 3;
}

.chatMenuWrapper,
.chatBoxWrapper,
.chatOnlineWrapper {
	padding: 10px;
	height: 100%;
}

.noConversationText {
	position: absolute;
	top: 10%;
	font-size: 50px;
	color: rgb(224, 220, 220);
	cursor: default;
}

.token-bound-nft-details {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
}

.token-owner-image {
	width: 40px;
	height: 40px;
	border-radius: 20px;
}

.robot-wallet-email {
	color: white;
	margin-top: 16px;
}

@media screen and (max-width: 768px) {
	.chatMenu {
		flex: 1;
	}

	.chatMenuInput {
		display: none;
	}

	.chatBox {
		flex: 10;
	}

	.chatOnline {
		flex: 1px;
	}
}

/* chat container */

.card-body {
	padding: 0 !important;
}

#accordion-pills {
	width: 100% !important;
	justify-content: space-between !important;
}

.bid-input {
	display: flex;
	align-items: baseline;
}

.bid-input .deposit {
	width: 159.658px;
	height: 48px;
	flex-shrink: 0;
	border-radius: 25px;
	border: 2px solid #fc4ba3;
	background: #fff;
	color: #fc4ba3;
	text-align: right;
	margin-right: 9px;
}

.bid-input input {
	font-size: 20px;
}

.bid-input .input-label {
	color: #fc4ba3;
	text-align: center;
	font-size: 20px;
}

.input-label {
	text-align: left !important;
	margin: 0;
}

@media only screen and (max-width: 991px) {
	.services-tabs {
		margin-top: 36px;
		justify-content: space-around;
	}
	.nav-link {
		font-size: 14px;
	}
}

@media only screen and (max-width: 540px) {
	.modal-content {
		margin-top: 36px;
		width: auto !important;
	}
}
