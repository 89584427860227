.rnmtokenused-section {
  margin-top:1px
}

.rnmtokenused-section .container {
  max-width: 1920px;
}

.rnm-token-used-content .nftr-text-box {
  position: absolute;
  left: 100px;
}

.rnm-token-used-content {
  position: relative;
}

.rnm-token-used-content .nftr-text-box p {
  max-width: 550px;
}

.rnmtokenused-section .pink-shape {
  top: 300px;
}

.rnmtokenused-thumbnail-wrapper {
  text-align: right;
}

.rnmtokenused-thumbnail-wrapper img {
  max-width: 65%;
}

@media screen and (max-width: 1440px) {
  .rnmtokenused-section .pink-shape {
    max-width: 150px;
  }
}
@media screen and (max-width: 1199px) {
  .rnmtokenused-thumbnail-wrapper img {
    max-width: 425px;
  }
}
@media screen and (max-width: 991px) {
  .rnmtokenused-section {
    margin-top: 100px;
  }
  .service-box h3 {
    font-size: 25px;
  }
}
@media screen and (max-width: 767px) {
  .rnm-token-used-content .nftr-text-box {
    position: relative;
    left: 0;
    text-align: center;
  }

  .rnmtokenused-thumbnail-wrapper img {
    width: 100%;
  }
}

@media screen and (max-width: 585px) {
  .rnm-token-used-content .nftr-text-box {
    text-align: left;
  }
  .rnmtokenused-section .pink-shape {
    display: none;
  }
}
