.select-asset {
	display: flex;
	/* gap: 48px; */
	color: #fff;
}

.token-bound-account {
	position: relative;
	border: 1px solid #77e5c4;
	margin-top: -54px;
	padding: 32px;
	border-radius: 16px;
	box-shadow: 0px 32px 64px -12px rgba(16, 24, 40, 0.14);
	background: #171e44;
}
.deploy-account-btn-container {
	margin: 24px 0;
}
.token-bound-account-tabs {
	margin-top: 32px;
}
.nftr-account-address {
	margin-right: 16px;
}

.token-bound-address-container {
	display: flex;
	justify-content: start;
	align-items: center;
}

.external-link-icon {
	margin-left: 16px;
	text-decoration: none;
	color: #3fecc6;
}

.external-link-icon:hover {
	color: #3fecc6;
}

.token-bound-address {
	border: 1px solid #3fecc6;
	padding: 8px 16px;
	background-color: rgba(63, 236, 198, 0.1);
	border-radius: 20px;
	color: #3fecc6;
}

.deploy-account-btn {
	padding: 8px 16px;
	background: none;
	outline: none;
	border-radius: 20px;
	text-transform: uppercase;
	color: #77e5c4;
	border: 1px solid #77e5c4;
	font-size: 0.875rem;
}

.deploy-account-btn:hover {
	background-color: #3fecc6;
	color: #fff;
}

.token-address {
	margin-right: 16px;
}

.copy-account {
	cursor: pointer;
	display: flex;
	/* gap: 48px; */
	justify-content: space-between;
}

.token-images {
	width: 25px;
	height: 25px;
}

.asset-list {
	margin-top: 16px;
	gap: 16px;
}

.select-asset .nft-address {
	padding-top: 24px;
}

.asset-details {
	color: #fc4ba3;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 8px;
	background: rgba(255, 255, 255, 0.1);
	border-radius: 8px;
	padding: 12px 20px;
	min-height: 67px;
}

.flex-row-center {
	display: flex;
	align-items: center;
	gap: 32px;
}

.flex-row-center p {
	margin: 0;
	color: #fff;
	font-weight: 600;
}

.token-bound-account-transfer {
	margin-top: 64px;
}

.tba-asset-input-container {
	display: flex;
	gap: 32px;
	align-items: flex-end;
	margin-bottom: 24px;
}

.tba-asset-input-container button {
	outline: none;
	border: none;
	background: none;
	color: #fc4ba3;
	text-decoration: underline;
	text-transform: uppercase;
	font-weight: 500;
}

.tba-transfer-amount {
	margin-top: 24px;
}

.token-bound-account-token-dropdown-wrapper {
	margin-top: 24px;
}

.token-bound-account-token-dropdown-list {
	font-display: flex;
	justify-content: start;
	gap: 32px;
}

.token-bound-account-list-item {
	margin-bottom: 16px;
	text-align: left !important;
}

.token-bound-account-token-dropdown-list {
	display: flex;
}

.tba-token-placeholder {
	display: flex;
	align-items: center;
	gap: 32px;
}

.tba-symbol {
	font-size: 16px;
}

.tba-transfer-field {
	margin-top: 32px;
}

.tba-input-field {
	display: flex;
	flex-direction: column;
	padding: 32px 0;
}

.tba-input-send-btn {
	display: flex;
	align-items: center;
	gap: 48px;
}

.tba-input {
	border: 2px solid #fc4ba3;
	color: #fc4ba3;
	padding: 12px;
	width: 360px;
	height: 48px;
	border-radius: 48px;
	background: transparent;
}

.tba-token-container {
	gap: 24px;
	align-items: center;
}

.tba-token-container p {
	margin: 0;
}

.tba-input::placeholder {
	color: #fc4ba3;
}

.tba-send-button {
	padding: 12px 16px;
	background: none;
	outline: none;
	border-radius: 50px;
	font-size: 0.75rem;
	font-weight: 500;
	text-transform: uppercase;
	color: #fff;
	border: 1px solid #77e5c4;
	background-color: #030a34;
	opacity: 0.8;
}

.tba-select-token-text {
	color: #fc4ba3 !important;
	font-size: 16px;
}

.clipboard-alert {
	position: absolute;
	top: 50px;
	left: 0;
}

.token-bound-collectibles {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(0, 200px));
	gap: 48px; /* Adjust the gap between elements as needed */
	max-width: 714px;
	margin: 0 auto; /* Center the grid container */
}

.select-recipient.collectibles .custom-radio-button p {
	color: #fff !important;
}

.token-bound-collectibles-asset-container {
	display: flex;
	flex-direction: column;
}

.token-bound-collectibles-asset {
	border-radius: 8px;
	cursor: pointer;
}

.token-bound-collectibles-asset:hover {
	border-radius: 8px;
	cursor: pointer;
	border: 4px solid #3fecc6;
}

.token-bound-collectibles-asset.selected {
	border: 4px solid #3fecc6;
}

.token-bound-collectibles-asset-name {
	margin-top: 16px;
	padding-left: 8px;
	color: #fc4ba3;
	font-weight: 700;
	font-size: 1.25rem;
	font-weight: lighter;
	margin-bottom: 0;
}

.token-bound-collectibles-asset-name.id {
	color: #3fecc6;
	font-weight: 500;
	margin: 0;
}

.asset-link {
	text-decoration: none;
}

.token-bound-account-transfer-nft {
	display: flex;
	justify-content: flex-start;
}

.tba-send-button.collectibles {
	width: fit-content;
	margin-top: 32px;
}

.tba-transfer-address {
	background: none;
	color: #fc4ba3;
	padding: 6px 24px;
	border-radius: 32px;
	border: 2px solid #fc4ba3;
	width: 70%;
	font-size: 22px;
	margin-bottom: 8px;
}

.tba-transfer-address::placeholder {
	color: #fc4ba3;
}

.tba-account-status {
	color: white;
	margin: 8px;
}

.walletconnect-icon {
	width: 24px;
	height: 24px;
	margin-right: 8px;
}

.LoginWithNFTModal {
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-bottom: 16px;
}

.LoginWithNFTModal h4 {
	font-size: 16px;
	font-weight: 700;
	margin: 0;
}

.LoginWithNFTModal p {
	margin: 0;
	text-align: left;
}

.font-bold {
	font-weight: 700;
}

.walletconnect-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.walletconnect-input {
	padding: 8px 16px;
	width: 100%;
}

.select-token-wrapper.deposit::placeholder {
	color: #030a34;
}

.offer-btn {
	margin-top: 16px;
}

.tba-name p {
	margin-bottom: -8px;
	margin-top: 8px;
	font-size: 24px;
	padding-left: 8px;
}

.input-image {
	position: absolute;
	left: 12px;
	top: 50%;
	transform: translateY(-50%);
	width: 30px;
	height: auto;
}

.cutom-contract-input::placeholder {
	position: absolute;
	left: 16px;
}

/* MODAL STYLES */
/* Modal.css */
.modal {
	display: none;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	overflow: auto;
}

.tba-modal-content {
	background-color: #fefefe;
	margin: 15% auto;
	padding: 20px;
	border: 1px solid #888;
	width: 80%;
	max-width: 620px;
	position: relative;
	border-radius: 16px !important;
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.tba-modal-heading {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.tba-modal-content h4 {
	text-align: left;
	text-transform: uppercase;
	font-size: 24px;
	font-weight: 600;
	margin: 0;
}

.tba-modal-content h5 {
	text-align: left;
	text-transform: uppercase;
	font-size: 16px;
	font-weight: 500;
	margin: 0;
}

.token-balance {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.token-balance p {
	margin: 0;
	font-weight: 700;
}

.close-button {
	color: #030a34;
	font-size: 32px;
}

.close-button:hover,
.close-button:focus {
	color: #030a34;
	text-decoration: none;
	cursor: pointer;
}

.input-with-btn {
	position: relative;
	display: inline-block;
}

.image-with-btn {
	position: relative;
	display: inline-block;
}

.image-with-btn .tba-nft-send-btn {
	position: absolute;
	top: 16px;
	right: 16px;
	cursor: pointer;
	outline: none;
	border: none;
	background: none;
}

.tba-nft-send-btn:hover {
	color: #3fecc6;
}

.tba-amount-input {
	width: 100% !important;
}

.input-btn {
	position: absolute;
	right: 24px;
	top: 50%;
	transform: translateY(-50%);
	height: auto;
	outline: none;
	border: none;
	background: none;
	color: #fc4ba3;
	text-decoration: underline;
}

.select-recipient {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.select-recipient .custom-radio-button p {
	color: #030a34 !important;
}

.tba-transfer-btn {
	border: 1px solid #fc4ba3;
	padding: 12px 16px;
	border-radius: 32px;
	color: #fff;
	background-color: #fc4ba3;
	text-transform: uppercase;
	font-weight: 600;
}
@media screen and (max-width: 600px) {
	.token-address {
		font-size: 0.75rem;
	}

	.deploy-account-btn {
		font-size: 0.75rem;
		padding: 8px;
	}

	.nftr-account-address,
	.tba-account-status {
		font-size: 0.75rem;
	}

	.token-bound-account {
		padding: 32px 8px;
	}

	.token-bound-address {
		padding: 8px;
	}

	.token-address {
		margin-right: 8px;
	}
}
