.banner {
  height: 100vh !important;
}

.banner-shape-left {
  left: 0;
}

.banner-shape-right {
  right: 0;
}

.banner-text h1 {
  font-size: 70px;
  font-weight: bold;
  text-transform: uppercase;
}

.banner-text p {
  font-weight: bold;
  color: #0955ce;
}

.banner-text .banner-button-group {
  margin-top: 10px;
  margin-bottom: 30px;
}

.banner-text .banner-button-group a {
  margin: 0 15px;
}

@media screen and (max-width: 1921px) {
  .banner-shapes img {
    max-width: 530px;
  }
}

@media screen and (max-width: 1680px) {
  .banner {
    height: auto !important;
    padding-top: 250px;
    padding-bottom: 130px;
  }

  .banner-shapes img {
    max-width: 400px;
    margin-top: -220px;
  }
  .banner-text h1 {
    font-size: 50px;
    max-width: 70%;
    margin: 0 auto;
  }

  .banner-text p {
    font-size: 30px;
  }

  .banner-text .banner-button-group {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1199px) {
  .banner {
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 991px) {
  .banner-text h1 {
    font-size: 40px;
  }
  .banner-shapes {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .banner {
    padding-top: 200px;
    padding-bottom: 100px;
  }

  .banner-text p {
    font-size: 18px;
  }

  .banner-text .banner-button-group {
    margin-top: 10px;
  }

  .banner-text .banner-button-group a {
    margin: 0;
    margin-top: 10px !important;
    width: 100%;
  }
}

@media screen and (max-width: 585px) {
  .banner-text h1 {
    font-size: 28px;
    max-width: 100%;
  }
}
