.noitification {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
}

.message-notification-container {
	position: relative;
	padding-right: 12px;
}

.message-notification-count {
	color: red;
	position: absolute;
	top: -10px;
	font-weight: 900;
}

/* #notification-img {
	margin-left: -10px !important;
} */

#wallet {
	/* border: 5px solid red; */
	/* margin-right: 36px; */
}

.my-wallet {
	/* margin-top: 16px; */
}

.balances {
	display: flex;
	gap: 8px;
	max-width: 100%;
	justify-content: flex-end;
}

.balances p {
	display: flex;
	gap: 4px;
}
.wallet-email {
	display: flex;
	align-items: center;
	gap: 8px;
	justify-content: flex-end;
	cursor: pointer;
}

.email-edit {
	display: flex;
	align-items: center;
	gap: 8px;
}

.add-btn {
	cursor: pointer;
}

@media only screen and (max-width: 991px) {
	.wallet-email {
		justify-content: center;
	}
	.text-center.home-button {
		width: 100%;
	}

	.noitification {
		justify-content: center;
	}

	.waller-wrapper {
		display: flex;
		justify-content: center;
	}

	.balances {
		justify-content: center;
	}

	.my-wallet {
		text-align: center;
	}

	.message-notification-container {
		padding: 0;
	}

	.golden-ticket {
		text-align: center;
	}
}

@media screen and (max-width: 991px) {
	.mobile-flex-center {
		display: flex;
		justify-content: center;
	}
}
