.rnm-token-content .nftr-text-box {
  position: absolute;
  bottom: 24%;
  left: 100px;
}

.rnm-token .container {
  max-width: 1920px;
}

section.rnm-token {
  margin-top: -260px;
}

@media screen and (max-width: 1680px) {
  .rnm-token-content .nftr-text-box {
    text-align: left;
  }
  section.rnm-token {
    margin-top: -75px;
  }
}

@media screen and (max-width: 1440px) {
  .rnm-token-content > img {
    max-width: 70%;
    margin: 0 auto !important;
  }

  .rnm-token-content {
    text-align: center;
  }
}

@media screen and (max-width: 1199px) {
  .rnm-token-content > img {
    opacity: 0.3;
  }
}

@media screen and (max-width: 585px) {
  .rnm-token-content .nftr-text-box {
    position: static;
  }
}

@media screen and (max-width: 585px) {
  .rnm-token-content > img {
    opacity: 1;
    margin-bottom: 30px !important;
  }

  .rnm-token-content > img {
    max-width: 100%;
  }
}
