::-webkit-scrollbar {
	width: 0px;
}

/* Track */

/* Handle */
::-webkit-scrollbar-thumb {
	background: none !important;
}

.nft-image {
	height: 50px;
	width: auto;
	/* margin-left: 30px; */
	border-radius: 25px;
}

.select-own-nft-image {
	width: 611px;
}

.select-own-nft {
	width: 530px;
}

.select-collection {
	width: 360px;
	overflow: hidden;
}

#select-token-search {
	box-sizing: border-box;
	height: 48px;
	padding-left: 12px;
}

#select-token-search::placeholder {
	margin-right: 12px;
}

.token-list {
	overflow-y: scroll !important;
	overflow-x: hidden !important;
}
.token-list::-webkit-scrollbar {
	width: 4px;
	max-height: 200px !important;
}

.token-list::-webkit-scrollbar-thumb {
	max-height: 200px !important;
}

.token-list::-webkit-scrollbar-track {
	background: #fc4ba3;
	max-height: 200px !important;
	border-radius: 4px;
}

#select-token-search::placeholder {
	text-align: right;
}

@media only screen and (max-width: 768px) {
	.select-nft-container {
		flex-direction: column;
		column-gap: 12px;
	}

	.select-collection {
		width: 300px;
	}

	.select-token-text,
	.deposit-button {
		font-size: 16px;
	}
}

#select-token-search::placeholder {
	color: #fc4ba3;
	font-size: 22px;
	/* font-style: italic; */
}

.select-nft {
	margin-right: 16px;
}
