.colored-bg {
  background-color: #3fecc6;
  text-align: center;
  padding: 15px;
  margin-bottom: 50px;
}

.colored-bg h4 {
  color: #000;
  margin: 0;
  margin-top: 15px;
}

.nft-name-box p {
  margin: 0;
  color: #fff;
  font-size: 20px;
  text-align: center;
  margin-top: 20px;
}
.project-colored-bg {
  background-color: #3fecc6;
  color: #000000;
  text-align: center;
  padding-top: 115px;
  padding-bottom: 120px;
}

.project-colored-bg h4 {
  font-size: 28px;
  text-align: center;
}

a.primary-btn {
  background-color: #fc4ba3;
  color: #3fecc6;
  text-decoration: none;
  display: inline-block;
  padding: 13px 30px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  border-radius: 50px;
}

.project-colored-bg span {
  font-size: 22px;
  display: inline-block;
  margin-bottom: 14px;
}

.project-box {
  padding: 20px;
}

.project-section .container {
  max-width: 1720px;
}

.project-box {
  background-size: cover;
  background-position: top center;
  padding-top: 215px;
  padding-bottom: 147px;
}

.project-colored-bg {
  padding-bottom: 70px;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: -60px;
}

.nft-name-box-bg {
  padding: 20px;
  background-size: cover;
  padding-top: 215px;
  padding-bottom: 36px;
}

.nft-name-box-content {
  text-align: center;
  margin-top: -51px;
}

section.project-section {
  margin-top: -120px;
  margin-bottom: 80px;
  overflow: hidden;
}

.project-section .section-tite {
  margin-bottom: 100px;
}

.project-colored-bg h4 strong {
  display: block;
}

.project-colored-bg h4 {
  text-transform: uppercase;
}

.project-section .nftr-text-box img {
  margin-top: -40px;
}

@media screen and (max-width: 1680px) {
  a.primary-btn {
    font-size: 14px;
  }
  .project-section .container {
    max-width: 1320px;
  }
  .project-section h2 {
    font-size: 55px;
  }
  .project-box {
    padding-top: 100px;
    padding-bottom: 200px;
  }

  .project-colored-bg {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .project-colored-bg h4 {
    font-size: 25px;
  }
  .nft-name-box-bg {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 1440px) {
  .project-colored-bg h4 {
    font-size: 18px;
    line-height: 1.6;
  }
  .project-colored-bg {
    padding-bottom: 35px;
    padding-top: 55px;
  }
}

@media screen and (max-width: 1199px) {
  .nft-name-box-bg {
    background-image: unset !important;
    padding: 0 !important;
  }
  .project-box {
    background-image: unset !important;
    padding: 0 !important;
  }
  .project-box {
    background-image: unset !important;
    padding: 0 !important;
  }

  .project-colored-bg {
    margin-bottom: 0;
  }

  .project-colored-bg h4 {
    font-size: 35px;
  }
  .nft-name-box-content {
    margin-top: 0;
  }
  .nft-name-box-bg img {
    width: 100%;
  }
  .project-section h2 {
    font-size: 40px;
  }
}

@media screen and (max-width: 767px) {
  section.project-section {
    margin-top: 100px;
  }
}

@media screen and (max-width: 585px) {
  .project-colored-bg h4 {
    font-size: 18px;
  }
}
